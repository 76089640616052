/**
 * displays paths to individual suites on the floor SVG image . This also shows the availability of each suite.
 */

import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import __ from "../../../../utilities/functions";
import svgData from "../data/suites.json";
import svgDataMobile from "../data/suitesMobile.json";

/**
 * Component displays each suite on the floor SVG
 * @param id - the id of the suite (gotten from db)
 * @param route - the route the suite will point to, the suite details page with beacons
 * @param number - the suite number (1 - 7)
 * @param inventoryItems - the details of the particular floor gotten from core inventory manager (the floor is an inventory group with suites attached to it as inventory items)
 * @param mobile
 * @param floor
 * @returns {JSX.Element}
 * @constructor
 */
function Suite({ id, route, number, inventoryItems, mobile, floor }) {
  id = Number(id);
  // go through the db inventory group details and pull out the details for this particular suite (inventory item)
  let suiteData;
  const suiteDetails = _.find(inventoryItems, (item) => {
    if (item.id === id) {
      suiteData = JSON.parse(item.data);
      return item;
    }
  });


  let availability;
  switch (suiteData?.availability) {
    case "available":
      availability = "suite_available";
      break;

    case "pending":
      availability = "suite_available_in_30";
      break;

    case "sold":
      availability = "suite_rented";
      break;

    default:
      availability = "dn"; // used dn (display:none) to ensure it is transparent until loaded
  }



  // rebuild the reactooltip lib
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  let jsonData;
  floor = Number(floor);
  if (floor === 8) {
    if (mobile) {
      jsonData = svgDataMobile;
    } else {
      jsonData = svgData.floor8;
    }
  } else if (mobile) {
    jsonData = svgDataMobile;
  } else {
    jsonData = svgData.allfloors;
  }

  // console.log("data datas", suiteData);
  return (
    <NavLink
      to={route}
      data-tip={`<span class="f3">${suiteData?.item}</span> <br />${
        suiteData?.description
      } <br />${suiteData?.sqft} sqft <br />${__.currency(
        suiteData?.price
      )}/month`}
      data-html={true}
    >
      {jsonData.map((suite, i) => {
        if (suite.suite === number) {
          switch (suite.type) {
            case "rect":
              return (
                <rect
                  x={suite.x}
                  y={suite.y}
                  width={suite.width}
                  height={suite.height}
                  className={availability}
                  key={i}
                />
              );

            case "polygon":
              return (
                <polygon
                  points={suite.points}
                  className={availability}
                  key={i}
                />
              );

            case "path":
              return <path d={suite.d} className={availability} key={i} />;

            default:
              return null;
          }
        }
      })}
    </NavLink>
  );
}

export default Suite;
