/**
 * displays desktop version of floor SVG.
 * this links to the individual Suites that are displayed as part of the SVG
 */

import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import _ from "lodash";
import svgData from "../data/floors.json";
import __ from "../../../../utilities/functions";
import Suite from "./Suite";

function Floor({ number }) {
  const dispatch = useDispatch();

  const [inventoryGroupData, setInventoryGroupData] = useState({
    inventory_items: [],
  });

  // get the right floor from json data
  const data = _.find(svgData, (floor) => {
    if (floor.floor === number) {
      return floor;
    }
  });

  // get details of entire floor to be passed on to the suites
  useEffect(() => {
    __.showLoadingBar(dispatch);
    __.get(`/inv/groups?id=${data.id}`).then((response) => {
      setInventoryGroupData(response.data[0]);
      __.hideLoadingBar(dispatch);
    });
  }, [data]);

  return (
    <div className="hide-on-med-and-down relative overflow-hidden">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={data.viewBox}
      >
        <image x={data.x} y={data.y} xlinkHref={data.image} />
        {data.suites.map((suite) => (
          <Suite
            number={suite.number}
            id={suite.id}
            route={`/suite/${suite.id}`}
            inventoryItems={inventoryGroupData.inventoryItems}
            key={suite.id}
            floor={number}
          />
        ))}
      </svg>
    </div>
  );
}

export default Floor;
