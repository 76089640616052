import { Fragment } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { useSelector } from "react-redux";
import { AnimatedSwitch } from "react-router-transition";
import ReactTooltip from "react-tooltip";
import ScrollToTop from "./utilities/ScrollToTop";
import Home from "./view/pages/home/Home";
import Loader from "./utilities/Loader";
import MyRedirect from "./utilities/redirect";
import Why from "./view/pages/why/Why";
import Location from "./view/pages/location/Location";
import ReservationPage from "./view/pages/reservation/ReservationPage";
import Building from "./view/pages/building/Building";
import Floor from "./view/pages/floor/Floor";
import Suite from "./view/pages/suite/Suite";
import ConnectPage from "./view/pages/connect/ConnectPage";
import ApplicationPage from "./view/pages/application/ApplicationPage";

function App() {
  const state = useSelector((store) => store);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route
            path="/suite/:id"
            component={Suite}
            key={document.location.href}
          />
          <Route
            path="/floor/:floor"
            exact
            component={Floor}
            key={document.location.href}
          />
          <Route
            path="/application"
            exact
            component={ApplicationPage}
            key={document.location.href}
          />
          <Route
            path="/reservation"
            exact
            component={ReservationPage}
            key={document.location.href}
          />
          <Route
            path="/connect"
            exact
            component={ConnectPage}
            key={document.location.href}
          />
          <Route
            path="/building"
            exact
            component={Building}
            key={document.location.href}
          />
          <Route
            path="/location"
            exact
            component={Location}
            key={document.location.href}
          />
          <Route
            path="/why"
            exact
            component={Why}
            key={document.location.href}
          />
          <Route
            path="/home"
            exact
            component={Home}
            key={document.location.href}
          />
          <Route path="/" exact component={Home} />
        </AnimatedSwitch>
        <NotificationContainer />
        {state.loading ? <Loader /> : null}
        {state.redirect.status ? <MyRedirect /> : ""}
      </ScrollToTop>
      <ReactTooltip backgroundColor="#1c2b4a" />
    </BrowserRouter>
  );
}

export default App;
