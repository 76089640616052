import { Fragment, useEffect } from "react";
import M from "materialize-css";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import Sidebar from "../../components/Sidebar";

function Nav({ details, group, id }) {
  let floorName;
  switch (group.name) {
    case "7":
      floorName = "Seventh";
      break;

    case "6":
      floorName = "Sixth";
      break;

    case "5":
      floorName = "Fifth";
      break;

    case "4":
      floorName = "Fourth";
      break;

    case "3":
      floorName = "Third";
      break;

    case "2":
      floorName = "Second";
      break;

    case "8":
      floorName = "Eight";
      break;

    default:
      floorName = "";
  }

  const noVideos = ["4", "5", "7", "10", "12"];

  useEffect(() => {
    M.Collapsible.init($(".collapsible"));
  }, []);
  return (
    <Fragment>
      <div className="row mt3">
        <div className="col">
          <h1 className="white-text f2 lh-title mb2">{floorName} Floor</h1>
          <h1 className="white-text f2 lh-title mv0">{details.item}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m12 l12 xl12 no-pd-hz f4 tj">
          <ul className="collapsible expandable">
            <li className="coll active">
              <div className="collapsible-header border white-text">
                <div className="flex">
                  <h6 className="pa3">apartment pricing</h6>
                </div>
              </div>
              <div className="collapsible-body collapsible-box">
                <ul className="mb3 white-text f5 tl">
                  <li className="mh4 pl3 mt2 mr3 i futura-light">
                    ${details.price} month
                  </li>
                  <li className="mh4 pl3 mr3 i">{details.description}</li>
                  <li className="mh4 pl3 mr3 i">{details.sqft} sq feet</li>
                  <li className="mh4 pl3 mr3 i">In-Suite laundry</li>
                  <li className="mh4 pl3 mr3 i">Parking Stall Included</li>
                  <li className="mh4 pl3 mb2 i light_blue">
                    Immediate Availability
                  </li>
                </ul>
              </div>
            </li>
            {!_.includes(noVideos, details.unit) ? (
              <li className="coll">
                <div className="border white-text">
                  <div className="flex">
                    <h6 className="pa3 pl4">
                      <NavLink
                        to={`/suite/${id}/video/${details.unit}`}
                        className="nav-anchor"
                      >
                        video tour
                      </NavLink>
                    </h6>
                  </div>
                </div>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </Fragment>
  );
}

export default Nav;
