import { NotificationManager as Notification } from "react-notifications";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import config from "./config";
import menuIndex from "./menuIndices";

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InVzZXJAZ3JvdXBjLmNhIiwidXNlcnR5cGUiOiJ1c2VycyIsInVzZXJJZCI6MTE4LCJmaXJzdG5hbWUiOiJKb2huIiwiYXV0aCI6dHJ1ZSwiaWF0IjoxNjIyNjA3MDM0fQ.p7qZj0DfOWZcOu1hCzlxLSGSFgYoAOc2b34e_BZ8FcQ'

const functions = {
  stringToArray: (string, separator) => {
    const arr = [];
    const stringArr = string.split(separator);
    stringArr.map((char) => arr.push(char.trim()));

    return arr;
  },

  arrayToString: (array, joiner) => array.join(joiner),

  // this converts the JSON data into comma separated string
  parseJSON: (str) => {
    // convert to array
    let text = str;
    text = JSON.parse(text);
    return text.join(",");
  },

  // show loading bar
  showLoadingBar: (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: { value: true } });
  },

  // hide loading bar
  hideLoadingBar: (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: { value: false } });
  },

  displayErrorMessage: (message) => {
    Notification.error(message);
  },

  displaySuccessMessage: (message) => {
    Notification.success(message);
  },

  redirect(dispatch, destination) {
    dispatch({ type: "REDIRECT", payload: { status: true, destination } });
  },

  // api:GET
  async get(endpoint) {
    const response = await axios({
      method: "get",
      url: `${config.apiUrl}${endpoint}`,
      headers: {
        token: config.apiToken,
      },
    });

    return response;
  },

  // api:POST
  async post(endpoint, data) {
    const response = await axios({
      method: "post",
      url: `${config.apiUrl}${endpoint}`,
      data,
      headers: {
        token,
      },
    });

    return response;
  },

  // api: PATCH
  async patch(endpoint, data) {
    const response = await axios({
      method: "patch",
      url: `${config.apiUrl}${endpoint}`,
      data,
      headers: {
        token: localStorage.getItem("token"),
        domain: localStorage.getItem("default_domain"),
      },
    });

    return response;
  },

  // api:: DELETE
  async delete(endpoint) {
    const response = await axios({
      method: "delete",
      url: `${config.apiUrl}${endpoint}`,
      headers: {
        token: localStorage.getItem("token"),
        domain: localStorage.getItem("default_domain"),
      },
    });

    return response;
  },

  // delete confirmation dialogue
  confirmDelete(deleteFunction) {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes!",
          onClick: () => {
            deleteFunction();
          },
        },
        {
          label: "Nah!!",
          onClick: () => {
            this.displaySuccessMessage("Heh!");
          },
        },
      ],
    });
  },

  /// /call this funciton to update a list when an entry is deleted from it
  updateDelete(list, deletedId, updateList) {
    const newList = list.filter((item) => {
      if (item.id !== deletedId) {
        return true;
      }
      return false;
    });

    updateList(newList);
  },

  // get menu index
  getMenuIndex(menu) {
    return menuIndex[menu];
  },

  // confirmation dialogue
  confirmAction(message, fn) {
    confirmAlert({
      title: "Confirmation",
      message,
      buttons: [
        {
          label: "Yes!",
          onClick: () => {
            fn();
          },
        },
        {
          label: "No!!",
          onClick: () => {
            // this.displaySuccessMessage("Heh!");
          },
        },
      ],
    });
  },

  currency(amount) {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(amount);
  },
};

export default functions;
