import { NavLink } from "react-router-dom";
import Menu from "../components/Menu";

function Footer() {
  return (
    <div className="wrapper z-max">
      <div className="pt5 pb5 pr4 pl4">
        <div className="row">
          <div className="hide-on-med-and-down col l3 xl3">
            <img
              src="https://groupcore.ca/cmscdn/BEOERURP9QStAlogo3.png"
              className="ml4"
              height="160"
              width="160"
              alt="logo"
            />
          </div>
          <div className="hide-on-large-and-up col s12 m12 center-align">
            <img
              className="responsive-img center-align"
              src="https://groupcore.ca/cmscdn/ZusXs8GIC8St.[A].png"
              alt="logo"
            />
          </div>
          <div className="col s12 m6 l3 xl3 font-typo">
            <ul className="font-futura-medium">
              <li className="pv3">
                <NavLink to="/reservation" className="white-text">
                  + Reservation Reservation{" "}
                  <span className="futura-light f5"></span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/application" className="white-text">
                  + Application Reservation{" "}
                  <span className="futura-light f5"></span>
                </NavLink>
              </li>
              <li className="pv3">
                <a href="#" className="white-text">
                  + Lease Overview{" "}
                  <span className="futura-light f5">(coming soon)</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="col s12 m12 l4 xl4">
            <p className="grey-text tj f5 typo_round">
              Saint [A] is located at the entrance to downtown, next to the
              community of Braeside, one the first neighbourhoods established in
              St. Albert. Braeside is a picturesque, mature, walkable community
              on the banks of the Sturgeon River, known for its lush and
              plentiful green spaces and proximity to downtown.
            </p>
          </div>
          <div className="col s12 m6 l2 xl2">
            <ul className="right d-flex-sm">
              <Menu className="nav_grey_text" />
            </ul>
          </div>
          <div className="clearfix hide-on-med-and-down" />
          <div className="col s12 m6 l12 xl12 hide-on-small-only">
            <a className="right ml3" href="#">
              <img
                className="hoverable"
                src="https://groupcore.ca/cmscdn/ofx9zsJJOOfacebook.png"
                height="30px"
                alt="facebook"
              />
            </a>
            <a className="right ml3" href="#">
              <img
                className="hoverable"
                src="https://groupcore.ca/cmscdn/HPusI6uCOxtwitter.png"
                height="30px"
                alt="twitter"
              />
            </a>
            <a className="right" href="#">
              <img
                className="hoverable"
                src="https://groupcore.ca/cmscdn/em7AetM8Kuyoutube.png"
                height="30px"
                alt="youtube"
              />
            </a>
          </div>
          <div className="row hide-on-med-and-up pl0 mt3">
            <div className="col s12 left ">
              <a
                href="https://www.facebook.com/Niche-Developments-114445763630545/?ref=page_internal"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="hoverable"
                  src="https://groupcore.ca/cmscdn/ofx9zsJJOOfacebook.png"
                  height="30px"
                  alt="facebook"
                />
              </a>
              <a
                className="mr3 ml3"
                href="https://twitter.com/nichedevelop"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="hoverable"
                  src="https://groupcore.ca/cmscdn/HPusI6uCOxtwitter.png"
                  height="30px"
                  alt="twitter"
                />
              </a>
              <a href="#">
                <img
                  className="hoverable"
                  src="https://groupcore.ca/cmscdn/em7AetM8Kuyoutube.png"
                  height="30px"
                  alt="youtube"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
