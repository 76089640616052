/**
 * controls the display of the suite SVG and also the 360 viewer.
 * A router switch has been used here to make it possible for the SVG image and the 360
 * viewer to share the same sidebar content.
 */
import { Fragment, useEffect, useState } from "react";
import { useParams, Switch, Route, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import InnerHeader from "../../sections/InnerHeader";
import StickyHeader from "../../sections/StickyHeader";
import Footer from "../../sections/Footer";
import Sidebar from "../../components/Sidebar";
import __ from "../../../utilities/functions";
import BeaconsSvg from "./BeaconsSvg";
import Nav from "./Nav";
import Pannellum from "../../../components/Pannellum/Pannellum";

// the pannellum config files for the different suites
import configSuite1 from "./configs/suite1.json";
import configSuite2 from "./configs/suite2.json";
import configSuite3 from "./configs/suite3.json";
import configSuite4 from "./configs/suite4.json";
import configSuite5 from "./configs/suite5.json";
import configSuite6 from "./configs/suite6.json";
import configSuite8 from "./configs/suite8.json";
import configSuite10 from "./configs/suite10.json";
import configSuite11 from "./configs/suite11.json";
import configSuite14 from "./configs/suite14.json";
import configSuite15 from "./configs/suite15.json";
import configSuite16 from "./configs/suite16.json";
import configSuite17 from "./configs/suite17.json";
import Spacer from "../../components/Spacer";
import Button from "../../../ui/Button";
import VideoPlayer from "./VideoPlayer";
import Analytics from "../../../Analytics/Analytics";

function Suite() {
  useEffect(() => {
    new Analytics().trackPageview("Suite Page");
  });
  const { id } = useParams();

  const dispatch = useDispatch();

  const [details, setDetails] = useState({});
  const [group, setGroup] = useState({});
  const [suiteData, setSuiteData] = useState({});

  // // send back to building  page is chosen suite isnt availbale
  // if (id > 151 || id < 49) {
  //   __.redirect(dispatch, "/building");
  // }

  useEffect(() => {
    __.showLoadingBar(dispatch);
    __.get(`/inv/items?id=${id}`).then((response) => {
      __.hideLoadingBar(dispatch);
      setSuiteData(response.data[0].data);
      setDetails(response.data[0]);
    });
  }, [id]);

  useEffect(() => {
    if (details.inventoryGroup) {
      __.get(`/inv/groups?id=${details?.inventoryGroup}`).then(
        (response) => {
          setGroup(response.data[0]);
        }
      );
    }
  }, [details]);

  /**
   * display SVG image with beacons
   *
   * @returns {JSX.Element}
   * @constructor
   */
  function Beacons() {
    // const { suite } = useParams();
    return (
      <div className="row relative stage3-bg mb0">
        <div className="col s12 xl3" />
        <div className="col s12 xl9 ph3">
          <div className="mt7 mt3-l pb7 pb3-l z-1" id="planBeacons">
            <BeaconsSvg
              suite={suiteData.unit}
              floor={group.name}
              id={id}
            />
          </div>
        </div>

        <Sidebar
          className="blue-bg hide-on-med-and-down"
          id="page-wrap"
          collapsible={true}
        >
          <Nav details={suiteData} group={group} id={details.id} />
          <Button route="/reservation" className="right mh4">
            Reserve Suite
          </Button>
        </Sidebar>
      </div>
    );
  }

  function VideoTour() {
    const { suite, id } = useParams();
    return (
      <div className="row relative stage3-bg mb0">
        <div className="col s12 xl3" />
        <div className="col s12 xl9 ph3">
          <div className="mt7 mt3-l pb7 pb3-l z-1" id="planBeacons">
            <VideoPlayer suite={suite} id={id} />
          </div>
        </div>

        <Sidebar
          className="blue-bg hide-on-med-and-down"
          id="page-wrap"
          collapsible={true}
        >
          <Nav details={suiteData} group={group} id={details.id} />
          <Button route="/reservation" className="right mh4">
            Reserve Suite
          </Button>
        </Sidebar>
      </div>
    );
  }

  function VrViewer() {
    let config;
    const { scene } = useParams();

    if (suiteData) {
      const suite = Number(suiteData.unit);

      switch (suite) {
        case 1:
          configSuite3.default.firstScene = scene;
          config = configSuite3;
          break;

        case 2:
          configSuite2.default.firstScene = scene;
          config = configSuite2;
          break;

        case 3:
          configSuite1.default.firstScene = scene;
          config = configSuite1;
          break;

        case 4:
          configSuite4.default.firstScene = scene;
          config = configSuite4;
          break;

        case 5:
        case 7:
          configSuite5.default.firstScene = scene;
          config = configSuite5;
          break;

        case 6:
          configSuite6.default.firstScene = scene;
          config = configSuite6;
          break;

        case 8:
          configSuite8.default.firstScene = scene;
          config = configSuite8;
          break;

        case 10:
          configSuite10.default.firstScene = scene;
          config = configSuite10;
          break;

        case 12:
          configSuite10.default.firstScene = scene;
          config = configSuite10;
          break;

        case 9:
        case 11:
        case 13:
          configSuite11.default.firstScene = scene;
          config = configSuite11;
          break;

        case 14:
          configSuite14.default.firstScene = scene;
          config = configSuite14;
          break;

        case 15:
          configSuite15.default.firstScene = scene;
          config = configSuite15;
          break;

        case 16:
          configSuite16.default.firstScene = scene;
          config = configSuite16;
          break;

        case 17:
          configSuite17.default.firstScene = scene;
          config = configSuite17;
          break;

        default:
          break;
      }
    }
    return (
      <div className="row relative mb0">
        <Pannellum id="any" config={config} /> {/* <Sidebar> */}
        <Sidebar className="blue-bg hide-on-small-and-down" collapsible={true}>
          <Nav details={suiteData} group={group} id={details.id} />
          <Button route="/reservation" className="right mh4">
            Reserve Suite
          </Button>
        </Sidebar>
      </div>
    );
  }

  return (
    <Fragment>
      <InnerHeader />
      <StickyHeader />
      <Switch>
        <Route path="/suite/:id/vr/:suite/:scene" exact component={VrViewer} />
        <Route
          path="/suite/:id/video/:suite"
          exact
          render={() => <VideoTour />}
        />
        <Route
          path="/suite/:id"
          exact
          render={() => <Beacons suiteData={suiteData} group={group} />}
        />
      </Switch>

      <Spacer />
      <Footer />
    </Fragment>
  );
}
export default Suite;
