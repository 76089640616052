import { useEffect } from "react";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import Menu from "../components/Menu";
import MobileMenu from "../components/MobileMenu";

function StickyHeader() {
  function stickyNav(sticky) {
    if (window.pageYOffset > sticky) {
      $("#hidden-navbar").fadeIn(500);
      $("#navbar").fadeOut();
    } else {
      $("#hidden-navbar").fadeOut();
      $("#navbar").fadeIn(500);
    }
  }

  useEffect(() => {
    const navbar = document.getElementById("navbar");
    // Get the offset position of the navbar
    const sticky = navbar.offsetTop;

    window.onscroll = () => {
      stickyNav(sticky);
    };
  }, []);

  return (
    <div className="row absolute w-100 top-0">
      <nav>
        <div className="nav-wrapper">
          <div id="hidden-navbar">
            <div className="wrapper dark-blue-bg">
              <div className="nav-wrapper">
                <div className="row">
                  <div className="col s6 m4 l3 xl3">
                    <NavLink to="/">
                      <img
                        className="pa3 ma2"
                        src="https://groupcore.ca/cmscdn/ZusXs8GIC8St.[A].png"
                        alt="saainta"
                      />
                    </NavLink>
                  </div>
                  <div className="col s6 m8 l9 xl9 ">
                    <a
                      href="#"
                      data-target="mobile-demo"
                      className="sidenav-trigger ma3 white-text hide-on-large-and-up right"
                    >
                      <i className="material-icons mt3">menu</i>
                    </a>
                    <ul className="right mt4 nav hide-on-med-and-down">
                      <Menu />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MobileMenu />
      </nav>
    </div>
  );
}

export default StickyHeader;
