import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

function Button({ route, icon, children, position = "left", className = "" }) {
  return (
    <NavLink
      to={route}
      className={`f4 mt3 link dim ba bw1 ph3 pv2 mb2 dib dark-blue-text btn_blue waves-effect waves-dark-blue white-text ${position} ${className}`}
    >
      <i className="material-icons md-36">{icon}</i> {children}
    </NavLink>
  );
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};

export default Button;
