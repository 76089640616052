/**
 * Buttons to be displayed on the floor page beside the SVG. To show Next, Previous and Back buttons
 */

import { Fragment } from "react";
import { useDispatch } from "react-redux";
import __ from "../../../utilities/functions";

function Buttons({ floor }) {
  const dispatch = useDispatch();

  // functions to control next, previous and back buttons
  function nextFloor() {
    __.redirect(dispatch, `/floor/${Number(floor) + 1}`);
  }

  function prevFloor() {
    __.redirect(dispatch, `/floor/${Number(floor) - 1}`);
  }

  function goBack() {
    __.redirect(dispatch, "/building");
  }
  return (
    <Fragment>
      <i
        id="takeBack"
        className="medium material-icons o-70"
        onClick={() => {
          goBack();
        }}
      >
        keyboard_backspace
      </i>
      {floor < 8 ? (
        <i
          id="floorSwitchForwardDesktop"
          className="medium material-icons grow-large o-70"
          onClick={() => {
            nextFloor();
          }}
        >
          keyboard_arrow_right
        </i>
      ) : null}

      {floor > 2 ? (
        <i
          id="floorSwitchBackDesktop"
          className="medium material-icons grow-large o-70"
          onClick={() => {
            prevFloor();
          }}
        >
          keyboard_arrow_left
        </i>
      ) : null}
    </Fragment>
  );
}

export default Buttons;
