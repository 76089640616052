/**
 * mobile version of the building SVG with colored floor overlays
 */
import { NavLink } from "react-router-dom";

function Mobile() {
  return (
    <div
      className="dark-blue-bg hide-on-med-and-up overflow-x-hidden"
      id="backgroundSVG-mobile"
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 600 1100"
        xmlSpace="preserve"
      >
        <image
          className="absolute overflow-auto"
          x="0"
          y="0"
          href="https://groupcore.ca/cmscdn/rvRY0mobile.png"
        />
        <NavLink to="/floor/8">
          <path
            className="floor7 o-60"
            id="floor7_mobile"
            d="M 41.884 387.498 L 92.98 354.939 L 92.98 349.711 C 108.991 333.7 138.571 328.982 141.225 330.936 L 177.348 344.482 C 212.258 325.506 262.465 319.034 298.553 318.815 L 335.39 318.34 C 376.612 319.26 432.944 326.317 455.692 341.33 L 491.039 329.662 C 491.039 329.662 524.973 342.274 524.973 342.427 C 524.973 342.58 544.143 383.222 544.143 383.222 L 554.112 393.65 L 552.425 316.816 L 523.597 220.422 C 431.148 157.565 207.905 138.446 92.236 240.762 L 92.32 270.08 L 3.662 340.616 L 76.922 364.93 L 41.884 387.498 Z"
          />

          <circle
            className="floor_circle"
            cx="135.496"
            cy="283.97"
            r="27.332"
          />
          <text
            className="floor_number"
            x="138.906"
            y="282.834"
            dx="-11.369"
            dy="10.232"
          >
            8
          </text>
        </NavLink>

        <NavLink to="/floor/7">
          <path
            className="floor6 o-60"
            id="floor6_mobile"
            d="M 40.48 480.812 L 40.807 421.882 L 91.417 395.482 L 93.019 350.013 C 97.798 342.147 126.102 328.402 141.02 330.605 L 177.791 344.563 C 185.117 337.739 238.732 317.262 298.268 319.172 L 335.594 318.539 C 371.032 317.601 431.769 325.599 455.437 340.567 L 491.136 329.626 L 524.929 341.916 L 543.866 382.565 L 553.718 392.746 L 554.963 458.083 C 554.963 458.083 548.249 446.929 548.249 446.816 C 548.249 446.703 546.086 446.702 545.973 446.702 C 545.86 446.702 536.526 427.809 536.526 427.809 L 535.616 422.347 L 527.422 399.926 L 491.23 388.318 L 460.957 402.544 L 420.003 394.654 L 409.799 403.867 C 386.735 399.094 366.722 398.497 337.581 397.934 L 299.02 397.934 C 272.506 398.953 258.57 399.485 229.358 403.33 L 220.346 396.385 C 220.346 396.385 218.617 396.662 218.686 396.592 C 218.755 396.522 217.994 399.29 217.994 399.29 L 174.769 407.584 L 141.646 395.67 C 133.345 394.43 105.317 405.72 91.772 415.442 L 90.77 458.906 L 40.48 480.812 Z"
          />

          <circle
            className="floor_circle"
            cx="133.222"
            cy="361.846"
            r="27.332"
          />
          <text
            className="floor_number"
            x="121.853"
            y="352.182"
            dx="4.547"
            dy="20.463"
          >
            7
          </text>
        </NavLink>
        <NavLink to="/floor/6">
          <path
            className="floor5 o-60"
            id="floor5_mobile"
            d="M 39.42 541.052 C 39.42 541.052 40.239 481.315 40.239 481.267 C 40.239 481.219 90.631 459.029 90.631 459.029 C 90.631 459.029 91.52 416.711 91.691 415.415 C 91.862 414.119 121.661 395.648 141.58 395.865 L 174.692 407.741 C 174.692 407.741 217.789 399.469 217.838 399.518 C 217.887 399.567 219.185 397.489 219.593 396.19 C 220.001 394.891 229.05 403.333 229.143 403.09 C 229.236 402.847 264.306 399.425 297.63 397.856 L 337.012 397.759 C 381.096 397.951 409.876 403.889 409.731 403.89 L 419.979 394.549 L 460.873 402.426 L 491.18 388.405 L 527.316 399.929 L 535.546 422.013 L 536.557 428.05 L 545.942 446.715 L 548.251 446.788 L 554.843 457.764 L 556.835 523.518 L 549.789 512.949 L 546.787 513.149 C 546.787 513.149 538.34 501.139 538.38 501.139 C 538.42 501.139 538.36 497.283 538.36 497.283 L 529.214 482.691 L 493.539 474.213 L 461.423 485.28 C 461.423 485.28 421.976 478.604 422.006 478.604 C 422.036 478.604 411.391 485.637 411.391 485.637 L 337.076 480.438 L 299.513 480.82 L 228.193 485.183 L 218.417 479.812 C 218.346 478.607 174.753 484.71 174.824 485.915 L 140.256 476.337 C 122.177 478.001 109.853 483.619 89.66 491.588 L 89.478 525.615 L 39.42 541.052 Z"
          />

          <circle
            className="floor_circle"
            cx="135.495"
            cy="442.562"
            r="27.332"
          />
          <text
            className="floor_number"
            x="126.401"
            y="436.31"
            dx="1.137"
            dy="15.916"
          >
            6
          </text>
        </NavLink>
        <NavLink to="/floor/5">
          <path
            className="floor4 o-60"
            id="floor4_mobile"
            d="M 38.657 602.698 L 39.337 541.085 L 89.399 525.73 L 89.746 491.602 C 106.186 483.83 127.114 476.926 139.896 476.444 L 174.684 485.879 C 175.096 484.781 212.293 479.245 217.531 479.717 L 227.697 484.948 C 227.742 484.77 298.987 480.367 298.852 480.909 L 337.068 480.476 C 337.287 480.235 413.176 483.654 411.351 485.661 L 420.668 478.578 L 461.321 485.325 L 493.482 474.3 L 528.889 482.607 L 538.201 497.286 L 538.382 500.975 L 546.699 513.133 L 549.691 512.903 L 556.963 523.952 L 558.27 586.273 L 551.756 581.262 L 548.75 581.229 L 531.738 565.216 L 495.624 559.473 C 495.624 559.473 462.384 565.656 462.429 565.656 C 462.474 565.656 422.955 561.673 422.955 561.673 L 411.777 566.062 C 382.804 562.997 374.237 563.543 337.929 562.582 L 299.414 563.55 C 278.79 563.305 244.34 564.039 228.198 565.471 L 217.615 561.944 L 174.596 565.881 L 139.745 560.28 C 143.14 559.466 114.302 559.386 87.587 568.517 L 87.586 594.273 L 38.657 602.698 Z"
          />
          <circle
            className="floor_circle"
            cx="136.632"
            cy="525.553"
            r="27.332"
          />
          <text
            className="floor_number"
            x="125.264"
            y="527.258"
            dx="3.411"
            dy="7.958"
          >
            5
          </text>
        </NavLink>
        <NavLink to="/floor/4">
          <path
            className="floor3 o-60"
            id="floor3_mobile"
            d="M 37.36 666.539 L 38.422 603.399 L 87.401 594.618 L 87.484 568.725 C 87.048 568.274 114.019 560.307 139.47 560.406 L 174.192 565.918 L 217.281 561.959 L 228.002 565.409 C 227.839 564.271 298.965 562.432 299.178 563.926 L 337.945 562.7 C 335.598 562.799 375.07 562.641 411.459 566.008 L 422.873 561.769 L 462.135 565.713 L 495.574 559.424 L 531.856 565.263 L 548.816 581.208 L 551.705 581.235 L 558.264 586.166 L 558.957 652.297 L 550.941 649.82 C 550.941 649.82 541.574 646.442 541.619 646.397 C 541.664 646.352 533.144 646.278 533.144 646.278 L 496.697 646.784 L 464.525 648.548 L 425.115 648.972 L 337.936 649.207 L 299.731 649.526 L 217.305 649.772 L 174.244 649.13 C 174.244 649.13 138.663 647.277 138.762 647.277 C 138.861 647.277 86.772 649.382 86.772 649.382 L 86.714 664.882 L 37.36 666.539 Z"
          />
          <circle
            className="floor_circle"
            cx="137.769"
            cy="606.27"
            r="27.332"
          />
          <text
            className="floor_number"
            x="143.454"
            y="605.702"
            dx="-12.505"
            dy="11.368"
          >
            4
          </text>
        </NavLink>
        <NavLink to="/floor/3">
          <polygon
            className="floor2 o-60"
            id="floor2_mobile"
            points="36.75 729.781005859375 37.194000244140625 666.8690185546875 87.0260009765625 665.1799926757812 87.01699829101562 649.5880126953125 138.79800415039062 647.531982421875 173.7050018310547 649.3460083007812 216.9499969482422 650.072998046875 299.0429992675781 649.7069702148438 337.7770080566406 649.302001953125 425.0480041503906 649.2550048828125 463.81500244140625 648.7750244140625 496.75201416015625 647.0609741210938 532.5889892578125 646.5549926757812 541.4949951171875 646.6640014648438 559.2080078125 652.656005859375 560.9450073242188 716.2479858398438 554.594970703125 719.4010009765625 552.0809936523438 718.2230224609375 543.676025390625 720.8150024414062 543.7069702148438 728.0919799804688 535.323974609375 732.7960205078125 499.6029968261719 736.1630249023438 464.9930114746094 732.5390014648438 433.2030029296875 736.0969848632812 339.0899963378906 736.9559936523438 300.375 736.7219848632812 207.48300170898438 736.35400390625 174.19500732421875 732.2930297851562 138.2689971923828 735.6339721679688 85.00399780273438 734.14599609375"
          />
          <circle
            className="floor_circle"
            cx="141.18"
            cy="689.261"
            r="27.332"
          />
          <text
            className="floor_number"
            x="126.401"
            y="701.198"
            dx="6.821"
            dy="-2.274"
          >
            3
          </text>
        </NavLink>
        <NavLink to="/floor/2">
          <path
            className="floor1 o-60"
            id="floor1_mobile"
            d="M 36.885 730.519 L 37.037 759.897 L 84.284 771.723 C 125.672 778.684 236.679 785.113 299.911 782.364 L 339.173 782.846 C 413.086 782.711 439.534 781.823 498.193 777.421 L 536.107 772.383 L 545.127 768.906 L 563.203 752.63 L 561.391 716.545 L 554.773 719.863 L 552.258 718.326 L 543.935 720.881 L 543.995 727.827 L 535.517 732.795 L 499.769 736.217 L 465.121 732.452 C 465.121 732.452 433.206 736.084 433.229 736.061 C 433.252 736.038 338.985 736.938 339.024 736.938 C 339.063 736.938 300.287 736.764 300.287 736.764 L 207.483 736.295 L 174.245 732.277 L 138.488 735.646 L 84.981 734.119 L 36.826 729.987 L 36.885 730.519 Z"
          />
          <circle
            className="floor_circle"
            cx="141.18"
            cy="774.525"
            r="27.332"
          />
          <text
            className="floor_number"
            x="126.401"
            y="779.641"
            dx="9.095"
            dy="4.547"
          >
            2
          </text>
        </NavLink>
      </svg>
    </div>
  );
}

export default Mobile;
