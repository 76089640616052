/**
 * landing file for the building profile page
 */

import { Fragment, useEffect } from "react";
import M from "materialize-css";
import $ from "jquery";
import InnerHeader from "../../sections/InnerHeader";
import Footer from "../../sections/Footer";
import Desktop from "./svgs/Desktop";
import Mobile from "./svgs/Mobile";
import Sidebar from "../../components/Sidebar";
import Nav from "./Nav";
import Spacer from "../../components/Spacer";
import Analytics from "../../../Analytics/Analytics";

function Building() {
  useEffect(() => {
    M.Collapsible.init($(".collapsible"));
  }, []);

  useEffect(() => {
    new Analytics().trackPageview("Building Page");
  });

  return (
    <Fragment>
      <InnerHeader />

      <div id="stage1">
        <Desktop />

        <Mobile />

        <Sidebar collapsible={true}>
          <Nav />
        </Sidebar>
      </div>

      <Spacer />
      <Footer />
    </Fragment>
  );
}

export default Building;
