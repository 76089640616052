import { Fragment } from "react";
import { NavLink } from "react-router-dom";

function Menu({ className = null }) {
  const menu = [
    { label: "home", route: "/home" },
    { label: "why saint[a]", route: "/why" },
    { label: "location", route: "/location" },
    { label: "building profile", route: "/building" },
    { label: "reservation", route: "/reservation" },
    { label: "connect", route: "/connect" },
  ];
  return (
    <Fragment>
      {menu.map((item, i) => (
        <li key={i}>
          <NavLink
            to={item.route}
            activeClassName="active-nav"
            className={className}
          >
            {item.label}
          </NavLink>
        </li>
      ))}
    </Fragment>
  );
}

export default Menu;
