/**
 * Buttons to be displayed on the floor page beside the SVG. To show Next suite, Previous suite and Back to floor buttons
 */

import { Fragment } from "react";
import { useDispatch } from "react-redux";
import __ from "../../../utilities/functions";

function Buttons({ suite, floor }) {
  const dispatch = useDispatch();

  // functions to control next, previous and back buttons
  function nextSuite() {
    __.redirect(dispatch, `/suite/${Number(suite) + 1}`);
  }

  function prevSuite() {
    __.redirect(dispatch, `/suite/${Number(suite) - 1}`);
  }

  function goBack() {
    __.redirect(dispatch, `/floor/${floor}`);
  }
  return (
    <Fragment>
      <i
        id="takeBacksuite"
        className="medium material-icons o-70"
        onClick={() => {
          goBack();
        }}
      >
        keyboard_backspace
      </i>

      <i
        id="suiteSwitchForwardDesktop"
        className="medium material-icons grow-large o-70"
        onClick={() => {
          nextSuite();
        }}
      >
        keyboard_arrow_right
      </i>

      <i
        id="suiteSwitchBackDesktop"
        className="medium material-icons grow-large o-70"
        onClick={() => {
          prevSuite();
        }}
      >
        keyboard_arrow_left
      </i>
    </Fragment>
  );
}

export default Buttons;
