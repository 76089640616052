import { Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
import HomeHeader from "../../sections/HomeHeader";
import Spacer from "../../components/Spacer";
import Reservation from "../../forms/Reservation";
import Footer from "../../sections/Footer";
import Analytics from "../../../Analytics/Analytics";

function Home() {
  useEffect(() => {
    new Analytics().trackPageview("Homepage");
  });
  return (
    <Fragment>
      <HomeHeader />
      <Spacer className="sidepadding" />
      <div className="container-fluid white-bg sidepadding pt5 pb3">
        <div className="row">
          <div className="col s12 l4 xl4 hide-on-med-and-down">
            <div className="ml5">
              <span className="f4 section_num">01</span>
              <p className="f4 section_title">
                Saint [A] Zero
                <br />
                Emission project
                <br />
                <NavLink to="/why" className="heading-link">
                  learn more
                </NavLink>
              </p>
            </div>
          </div>
          <div className="pa-0 col s12 m12 l8 xl8">
            <div className="pa-0 col s12 m4 l4 xl4">
              <div className="white-bg">
                <img
                  className="materialboxed img-height-vh z-depth-4 w-100"
                  src="https://groupcore.ca/cmscdn/5RzE5la1.png"
                  alt="layer"
                />
              </div>
            </div>
            <div className="ph-3 col s12 m8 l8 xl8">
              <div className="white-bg">
                <img
                  className="materialboxed img-height-vh w-100 z-depth-4"
                  src="https://groupcore.ca/cmscdn/4qwah2.png"
                  alt="layer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid white-bg sidepadding pv3">
        <div className="row h-auto pl1-med">
          <div className="col s12 m12 l8 xl8 right pl0">
            <div className="row pl3-ns pl1">
              <div className="col s12 m12 l8 xl8 pl0">
                <h1 className="mv2 f1 dark-blue-text">
                  Canada’s 1st Zero Emission
                </h1>
                <h1 className="mv2 f1 dark-blue-text">Housing Project</h1>
              </div>
              <div className="col s12 m12 l10 xl10 pl0">
                <p className="dark-blue-text f2-l f3 lh-title futura-light mt0">
                  Saint [A] is located at the entrance to downtown, next to the
                  community of Braeside, one the first neighbourhoods
                  established in St. Albert. Braeside is a picturesque, mature,
                  walkable community on the banks of the Sturgeon River, known
                  for its lush and plentiful green spaces and proximity to
                  downtown.
                </p>
                <div className="row no-margin">
                  <p className="dark-blue-text f4 lh-copy futura-light col l6 xl6 no-margin pl0">
                    St.A Square applied zero emission technology achieves a
                    complete geothermal solution, eliminating the dependency on
                    fossil fuels for heating, cooling and air circulation.
                  </p>

                  <p className="dark-blue-text f4 lh-copy futura-light col l6 xl6 no-margin pl0">
                    Steel and concrete construction can last centuries compared
                    to wood frame buildings. Steel and concrete constructed
                    buildings require minimal lifetime structural maintenance,
                    creates a superior building envelope and is the “future” of
                    zero energy developments.
                  </p>
                </div>
                <div className="row no-margin">
                  <p className="dark-blue-text f4 lh-copy futura-light col l6 xl6 no-margin pl0">
                    With the geothermal heating and cooling already in place, we
                    have engineered St.A Square with a complete solar panel
                    energy system to offset common area and tenant utility
                    costs, creating the pathway for St. Alberts first zero
                    energy building.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid sidepadding white-bg pv3">
        <div className="row">
          <div className="col l4 xl4 hide-on-med-and-down">
            <div className="ml5">
              <span className="f4 section_num">02</span>
              <p className="f4 section_title">
                Saint [A]
                <br />
                Location Profile
                <br />
                <NavLink to="/location" className="heading-link">
                  learn more
                </NavLink>
              </p>
            </div>
          </div>
          <div className="col s12 m12 l8 xl8 pl0 no-padding-r-sm relative">
            <div className="hide-on-med-and-down absolute bottom-0 side_description_box">
              <span className="side_description writing_mode_vertical txtUpsideDown font-futura-medium ">
                featured suites
              </span>
            </div>
            <div>
              <video className="responsive-video" controls>
                <source
                  src="https://groupcore.ca/cmscdn/3GvlttheGROUP_StA_Suite03_LivingKitchen.mp4#t=1"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid white-bg sidepadding pv3">
        <div className="row pl1-med">
          <div className="col l4 xl4 hide-on-med-and-down">
            <div className="ml5">
              <span className="f4 section_num">03</span>
              <p className="f4 section_title">
                Saint [A]
                <br />
                Suite Profiles
                <br />
                <NavLink to="/building" className="heading-link">
                  learn more
                </NavLink>
              </p>
            </div>
          </div>
          <div className="col s12 m12 l8 xl8 pl0">
            <div className="row">
              <div className="col s12 m12 l12 xl12 pl1">
                <h1 className="dark-blue-text ma0 f1 lh-title">
                  Suite Profiles
                </h1>
              </div>
              <div className="col s12 m12 l6 xl6 f4 pl1">
                <p className="didot-medium dark-blue-text lh-copy tj">
                  St. [A] Square features bachelor, one bedroom, one bedroom
                  plus den, two bedroom and two bedroom plus den on all 7 floors
                  of residential living. Click on the suites below to view, tour
                  and reserve your favourite suite for occupancy May 2022! Make
                  sure to reserve your suite online and plan your move to zero
                  emission living
                </p>
                <p className="didot-medium dark-blue-text tj lh-copy">
                  A zero-energy / zero-emission home combines advanced
                  engineering and superior building systems with energy
                  efficiency, on-site solar panels and geothermal technology to
                  produce a better home. It is also these innovations that make
                  zero-energy and zero-emission homes inherently more durable,
                  comfortable, quiet, sustainable and most importantly,
                  affordable.
                </p>
              </div>
              <div className="col s12 m12 l6 xl6 f4 pl1">
                <p className="didot-medium dark-blue-text lh-copy tj">
                  {" "}
                  Simply put zero energy developments are built to a higher
                  standard, so you’ll breathe cleaner, fresher air, enjoy the
                  quiet nature of concrete and steel construction and be able to
                  regulate your environment in a much more comfortable way, free
                  from allergens and toxins.
                </p>
                <p className="didot-medium dark-blue-text lh-copy tj">
                  From then first day you move in to your home the total cost of
                  living is lower than that of a comparable standard home.
                  Zero-emission and zero-energy innovations will protect you
                  from a rapid rise in energy prices indefinitely.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid white-bg sidepadding section pt5 pb3">
        <div className="row">
          <div className="col s12 l4 xl4 hide-on-med-and-down" />

          <div className="col s12 l8 xl8 no-padding-left negative-ml5">
            <div className="row fit-content pl5">
              <div className="col s8 m4 l4 xl4 padding_4 relative">
                <div className="hover_container">
                  <a href="projects">
                    <img
                      className="z-depth-4"
                      src="https://groupcore.ca/cmscdn/G3Cqiin1.png"
                      alt="projects"
                    />{" "}
                  </a>

                  <NavLink to="/suite/48" target="_blank">
                    <div className="hover_img"></div>
                  </NavLink>
                </div>

                <div className="tab">
                  <NavLink to="/suite/48" target="_blank">
                    <img
                      src="https://groupcore.ca/cmscdn/WiwQAZAH.png"
                      alt="stage 2"
                    />{" "}
                  </NavLink>
                </div>
              </div>

              <div className="col s8 m4 l4 xl4 padding_4 relative">
                <div className="hover_container">
                  <img
                    className="z-depth-4"
                    src="https://groupcore.ca/cmscdn/l5KLUin2.png"
                    alt="stage 2"
                  />

                  <NavLink to="/suite/38" target="_blank">
                    <div className="hover_img" style={{ height: "99%" }} />
                  </NavLink>
                </div>

                <div className="tab">
                  <NavLink to="/suite/38" target="_blank">
                    <img
                      src="https://groupcore.ca/cmscdn/58lmJZA.png"
                      alt="stage 2"
                    />
                  </NavLink>
                </div>
                <NavLink to="/suite/38" target="_blank" />
              </div>

              <div
                className="col s8 m4 l4 xl4 padding_4 aos-init aos-animate"
                style={{ position: "relative" }}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-offset="0"
                data-aos-duration="1000"
              >
                <div className="hover_container">
                  <img
                    className="z-depth-4"
                    src="https://groupcore.ca/cmscdn/XIc43in3.png"
                    alt="stage 2"
                  />

                  <NavLink to="/suite/33" target="_blank">
                    <div className="hover_img" style={{ height: "99%" }} />
                  </NavLink>
                </div>

                <div className="tab">
                  <NavLink to="/suite/33" target="_blank">
                    <img
                      src="https://groupcore.ca/cmscdn/1Edd3A.png"
                      alt="stage 2"
                    />{" "}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid white-bg sidepadding section">
        <div className="row">
          <div className="col l4 xl4 hide-on-med-and-down">
            <div className="ml5">
              <span className="f4 section_num">04</span>
              <p className="f4 section_title">
                Residential Suites
                <br />
                Reservation
              </p>
            </div>
          </div>
          <div className="col hide-on-large-and-up">
            <h1 className="dark-blue-text">Residential Suites Reservation</h1>
          </div>
          <div className="col s12 l8 xl8 pl0 no-margin-top">
            <div className="row">
              <div
                className="col s12 m12 l12 xl12 padding-l2-m no-margin-top"
                id="form-container"
              >
                <Reservation />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
}

export default Home;
