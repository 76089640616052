/**
 * this displays the images and its beacons. the image is a suite's floor plans and the position of the beacons are in the JSON file
 */

import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import svgData from "./data/suites.json";
import Buttons from "./Buttons";

function BeaconsSvg({ suite, floor, id }) {
  const [suiteData, setSuiteData] = useState({});

  useEffect(() => {
    svgData.map((data) => {
      if (data.suite === suite) {
        setSuiteData(data);
      }
    });
  }, [suite, id]);

  return (
    <Fragment>
      <Buttons floor={floor} suite={id} />
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={suiteData.viewBox}
      >
        <image
          x="0"
          y="0"
          xlinkHref={suiteData.image}
          width="100%"
          height="100%"
        />
        {suiteData?.beacons?.map((beacon, i) => (
          <Link
            to={(location) => ({
              ...location,
              pathname: `${location.pathname}/${beacon.route}`,
            })}
            key={i}
          >
            <ellipse
              cx={beacon.x}
              cy={beacon.y}
              rx="40"
              ry="40"
              className="beacon"
            />
          </Link>
        ))}
      </svg>
    </Fragment>
  );
}

export default BeaconsSvg;
