/**
 * sidebar content for the floors page
 */

import { Fragment } from "react";
import "nouislider/distribute/nouislider.css";

function FloorNav({ floor }) {
  // to convert the floor numbers to names like 1 to first
  let floorName;
  switch (floor) {
    case "8":
      floorName = "Eight";
      break;
    case "7":
      floorName = "Seventh";
      break;

    case "6":
      floorName = "Sixth";
      break;

    case "5":
      floorName = "Fifth";
      break;

    case "4":
      floorName = "Fourth";
      break;

    case "3":
      floorName = "Third";
      break;

    case "2":
      floorName = "Second";
      break;

    default:
      floorName = "Unknown";
  }

  return (
    <Fragment>
      <div className="row mt5-ns mt4">
        <div className="col">
          <h1 className="white-text f2 lh-title mb2">{floorName} Floor</h1>
          <h1 className="white-text f2 lh-title mb4 mt0">Suite Availability</h1>
          <p className="white-text f4 measure ">
            Simply click on the suite you would like to view. If you're
            interested in the suite, simply click 'application' to start the
            rental process.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m12 l12 xl12 no-pd-hz f4 tj">
          <ul className="collapsible expandable">
            <li className="coll pa-0">
              <div className="collapsible-header dark-blue-text available">
                <div className="flex">
                  <h6 className="pa3">apartment available</h6>
                </div>
              </div>
            </li>
            <li className="coll pa-0">
              <div className="collapsible-header white-text reserved">
                <div className="flex">
                  <h6 className="pa3">apartment available in 30 days</h6>
                </div>
              </div>
            </li>
            <li className="coll pa-0">
              <div className="collapsible-header white-text rented">
                <div className="flex">
                  <h6 className="pa3">apartment rented </h6>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
}

export default FloorNav;
