import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CoreUtils from "groupcore-utils";
import __ from "../../utilities/functions";
import TextInput from "../../ui/TextInput";
import SwitchButton from "../../ui/SwitchButton";
import Submit from "../../ui/Submit";
import Recaptcha from "../../ui/Recaptcha";
import SelectMenu from "../../ui/SelectMenu";
import Checkbox from "../../ui/Checkbox";
import config from "../../utilities/config";

function Reservation() {
  const [form, setForm] = useState({});
  const dispatch = useDispatch();

  // code to handle form submisssion
  async function sendForm(e) {
    e.preventDefault();
    __.showLoadingBar(dispatch);

    if (!form.recaptcha) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage("Please confirm you are not a robot, to proceed!");
      return;
    }


    const coreUtil = new CoreUtils({projectId: config.projectId, apiToken: config.apiToken, apiUrl: config.apiUrl})
    try{
      const mailSubject = "St. [A] Square: Your Rental Application";
      const mailBody =
        "<div><p>Welcome to St. [A] Square, and thank you for your reservation request. Possession of suites will commence May 1, 2022. Your reservation request is a 3 part process; <br /> 1. Fill out the SECURE document for the St [A] Square rental management division. The PDF attached below is a fillable PDF, once you fill it out, please send to secure@stasquare.ca. <br />2. Once we receive your application, you will receive instruction through e-mail on submitting your $150.00* deposit to hold your desired suite. — *deposits are refundable if your application is NOT approved, less administration processing fees. Accepted applicants will have the deposit applied to their damage deposit.<br />3. The final step is completing your Lease Forms, which will be sent to you with your approval notice.</p><p>Have A Great Day! <br />St. [A] Square  Team.</p></div>";

      await coreUtil.submitForm({formData: form, formId: 25, email: form.email});
      await coreUtil.sendMail({recipientEmail: 'groupcollab2020@gmail.com', subject: mailSubject, htmlBody: mailBody, hasAttachment: true, attachmentUrl: 'https://groupcore.ca/cmscdn/StA_Square_Rental-Application.pdf', attachmentFilename:'pre-registration.pdf'})
      __.hideLoadingBar(dispatch);
      __.displaySuccessMessage(
        "Your form has been sent successfully! We will get back to you shortly. Thank you!!"
      );
      __.redirect(dispatch, "/home");

    }catch(err){
      console.log('caught error', err.response.data.message)
    }
  }

  return (
    <form
      onSubmit={(e) => {
        sendForm(e);
      }}
    >
      <TextInput
        label="First Name*"
        className="col s12 l3 pa2"
        state={form}
        isRequired={true}
        id="firstname"
        setState={setForm}
        type="text"
        value={form?.firstname}
      />
      <TextInput
        label="Last Name*"
        className="col s12 l3 pa2"
        state={form}
        isRequired={true}
        id="lastname"
        setState={setForm}
        type="text"
        value={form?.lastname}
      />
      <TextInput
        label="Email*"
        className="col s12 l3 pa2"
        state={form}
        id="email"
        setState={setForm}
        type="text"
        isRequired={true}
        value={form?.email}
      />
      <TextInput
        label="Phone*"
        className="col s12 l3 pa2"
        state={form}
        id="phone"
        setState={setForm}
        type="text"
        isRequired={true}
        value={form?.phone}
      />
      <TextInput
        label="Address"
        className="col s12 l6 pa2"
        state={form}
        id="address"
        setState={setForm}
        type="text"
        isRequired={false}
        value={form?.address}
      />
      <TextInput
        label="City"
        className="col s12 l2 pa2"
        state={form}
        id="city"
        setState={setForm}
        type="text"
        isRequired={false}
        value={form?.city}
      />

      <TextInput
        label="Province"
        className="col s12 l2 pa2"
        state={form}
        id="province"
        setState={setForm}
        type="text"
        isRequired={false}
        value={form?.province}
      />

      <TextInput
        label="Postal Code"
        className="col s12 l2 pa2"
        state={form}
        id="postalCode"
        setState={setForm}
        type="text"
        isRequired={false}
        value={form?.postalCode}
      />

      <TextInput
        label="Message"
        className="col s12 l8 pa2"
        state={form}
        id="message"
        setState={setForm}
        type="text"
        isRequired={false}
        value={form?.message}
      />

      <SelectMenu
        id="selected_floor"
        className="col s12 l2 pa2"
        onChange={(e) => {
          setForm({ ...form, selected_floor: e.target.value });
        }}
        list={[
          { key: "First-Floor", value: "First Floor" },
          { key: "Second-Floor", value: "Second Floor" },
          { key: "Third-Floor", value: "Third Floor" },
          { key: "Fourth-Floor", value: "Fourth Floor" },
          { key: "Fifth-Floor", value: "Fifth Floor" },
          { key: "Sixth-Floor", value: "Sixth Floor" },
          { key: "Seventh-Floor", value: "Seventh Floor" },
        ]}
        label="Choose Floor"
      />

      <SelectMenu
        id="selected_suite"
        className="col s12 l2 pa2"
        onChange={(e) => {
          setForm({ ...form, selected_suite: e.target.value });
        }}
        list={[
          { key: "Suite_1", value: "Suite 1" },
          { key: "Suite_2", value: "Suite 2" },
          { key: "Suite_3", value: "Suite 3" },
          { key: "Suite_4", value: "Suite 4" },
          { key: "Suite_5", value: "Suite 5" },
          { key: "Suite_6", value: "Suite 6" },
          { key: "Suite_7", value: "Suite 7" },
          { key: "Suite_8", value: "Suite 8" },
          { key: "Suite_9", value: "Suite 9" },
          { key: "Suite_10", value: "Suite 10" },
          { key: "Suite_11", value: "Suite 11" },
          { key: "Suite_12", value: "Suite 12" },
          { key: "Suite_13", value: "Suite 13" },
          { key: "Suite_14", value: "Suite 14" },
          { key: "Suite_15", value: "Suite 15" },
          { key: "Suite_16", value: "Suite 16" },
          { key: "Suite_17", value: "Suite 17" },
        ]}
        label="Choose Suite"
      />

      <Recaptcha setState={setForm} className="col s12" state={form} />

      <Checkbox
        label="yes, I would like to receive email updates"
        on={true}
        setState={setForm}
        state={form}
        id="newsletter"
        className="right"
      />

      <Submit className="col s12" position="right">
        Reserve Suite
      </Submit>
    </form>
  );
}

export default Reservation;
