import { useState, useEffect, Fragment } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import videos from "./data/videos.json";
import __ from "../../../utilities/functions";

function VideoPlayer(suite) {
  const [videolink, setVideolink] = useState();
  const dispatch = useDispatch();

  // get the right floor from json data
  const data = _.find(videos, (video) => {
    if (video.suite === suite.suite) {
      return video;
    }
  });

  useEffect(() => {
    setVideolink(data?.video);
  }, [data]);

  function goBack() {
    __.redirect(dispatch, `/suite/${suite.id}`);
  }

  return (
    <Fragment>
      <i
        id="takeBacksuite"
        className="medium material-icons o-70"
        onClick={() => {
          goBack();
        }}
      >
        keyboard_backspace
      </i>

      <video className="w-100 mb5" controls>
        <source src={videolink} />
      </video>
    </Fragment>
  );
}

export default VideoPlayer;
