import { useEffect } from "react";
import PropTypes from "prop-types";
import M from "materialize-css";
import $ from "jquery";

function SelectMenu({
  list,
  defaultValue,
  onChange,
  className,
  id,
  label = null,
}) {
  useEffect(() => {
    M.FormSelect.init($(`#${id}`), {
      classes: "select-wrapper",
    });
  });

  return (
    <div
      className={`file-field input-field nEngrave rounded ${className} selectMenu`}
    >
      <select
        id={id}
        value={defaultValue}
        onChange={(e) => {
          onChange(e);
        }}
        data-testid={id}
      >
        <option value="none">{label}</option>
        {list.map((option) => (
          <option key={option.key} value={option.key}>
            {`     ${option.value}`}
          </option>
        ))}
      </select>
    </div>
  );
}

// SelectMenu.propTypes = {
//   list: PropTypes.arrayOf(
//     PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })
//   ).isRequired,
//   initialValue: PropTypes.shape({
//     key: PropTypes.string,
//     value: PropTypes.string,
//   }),
//   onChange: PropTypes.func.isRequired,
//   className: PropTypes.string,
//   id: PropTypes.string.isRequired,
//   defaultValue: PropTypes.any,
//   label: PropTypes.string.isRequired,
// };

export default SelectMenu;
