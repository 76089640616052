import { NavLink } from "react-router-dom";
import MobileMenu from "../components/MobileMenu";

import Menu from "../components/Menu";
import StickyHeader from "./StickyHeader";

function HomeHeader() {
  return (
    <div className="fullScreenDiv">
      <div className="container headline headline-sm">
        <div className="row">
          <div className="col animate__animated animate__fadeInUp animate__delay-4s animate__slower">
            <h1 className="white-text headline_text headline_text-sm">
              Canada’s FIRST
            </h1>
            <h1 className="white-text headline_text headline_text-sm">
              Zero Emission
            </h1>
            <h1 className="white-text headline_text headline_text-sm">
              Multi-tenant Rentals
            </h1>
            <h2 className="white-text f3-l f4-m mb3 f5">
              Live in a Zero Emission Building with up to 65% lower utility
              expenses.
            </h2>
            <h2 className="white-text f3-l f4-m mt0 f5">
              NEW Tenants receive 6 months free Telus services and 3 months free
              parking!
            </h2>
            *posession may 1st, 2022
            <h2 className="white-text f4-l mt0 f5 right mb4">
              *posession may 1st, 2022
            </h2>
          </div>
        </div>
      </div>
      <video muted autoPlay playsInline className="video_banner">
        <source
          src="https://groupcore.ca/cmscdn/N6eYnStA_HomeBANNER_V1.mp4"
          type="video/mp4"
        />
      </video>
      <div className="row absolute w-100 top-0">
        <nav>
          <div className="nav-wrapper">
            <div id="navbar">
              <div className="row">
                <div className="col s6 m3 l3 xl3 dark-blue-bg no-margin center-align">
                  <NavLink to="/">
                    <img
                      className="responsive-img mt3"
                      src="https://groupcore.ca/cmscdn/iSbKg5YaTElogo.png"
                      width="170"
                      alt="logo"
                    />
                  </NavLink>
                </div>
                <div>
                  <a
                    href="#"
                    data-target="mobile-demo"
                    className="sidenav-trigger hide-on-large-and-up right"
                  >
                    <i className="ma3 material-icons white-text">menu</i>
                  </a>
                  <ul className="nav right hide-on-med-and-down typo-round mt5 pr5 animate__animated animate__fadeInDown animate__delay-3s animate__slower animate__repeat-0">
                    <Menu />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <MobileMenu />
      <StickyHeader />
    </div>
  );
}

export default HomeHeader;
