import { Fragment, useEffect } from "react";
import HomeHeader from "../../sections/HomeHeader";
import Spacer from "../../components/Spacer";
import Reservation from "../../forms/Reservation";
import Footer from "../../sections/Footer";
import InnerHeader from "../../sections/InnerHeader";
import StickyHeader from "../../sections/StickyHeader";
import Analytics from "../../../Analytics/Analytics";

function ReservationPage() {
  useEffect(() => {
    new Analytics().trackPageview("Reservation Form");
  });
  return (
    <Fragment>
      <InnerHeader />
      <StickyHeader />
      <Spacer className="sidepadding" />

      <div className="container-fluid white-bg sidepadding section">
        <div className="row">
          <div className="col l4 xl4 hide-on-med-and-down">
            <div className="ml5">
              <span className="f4 section_num">01</span>
              <p className="f4 section_title">
                Residential Suites
                <br />
                Reservation
              </p>
            </div>
          </div>
          <div className="col hide-on-large-and-up">
            <h1 className="dark-blue-text">Residential Suites Reservation</h1>
          </div>
          <div className="col s12 l8 xl8 pl0 no-margin-top">
            <div className="row">
              <div className="col s12 m12 l12 xl12 pl1">
                <h1 className="dark-blue-text ma0 f1 lh-title">
                  Residential Suites Reservation
                </h1>
              </div>
              <div
                className="col s12 m12 l12 xl12 padding-l2-m no-margin-top"
                id="form-container"
              >
                <Reservation />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
}

export default ReservationPage;
