import { Fragment, useState, useCallback, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import StickyHeader from "../../sections/StickyHeader";

import InnerHeader from "../../sections/InnerHeader";
import Sidebar from "../../components/Sidebar";
import LocationNav from "./LocationNav";
import Footer from "../../sections/Footer";
import Analytics from "../../../Analytics/Analytics";

function Index() {
  useEffect(() => {
    new Analytics().trackPageview("Location");
  });

  const containerStyle = {
    width: "100%",
    height: "120vh",
  };

  const center = {
    lat: 53.635440902987185,
    lng: -113.62383533667453,
  };

  const position = {
    lat: 53.635440902987185,
    lng: -113.62383533667453,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCopsirLawVGttreh8OqRv7ulxCLAp6DeE",
  });

  return (
    <Fragment>
      <StickyHeader />
      <InnerHeader />
      <div>
        <Sidebar className="dark-blue-bg white-text" collapsible={true}>
          <LocationNav />
        </Sidebar>
      </div>
      <div className="col s12">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={17}
            onLoad={() => {}}
          >
            <Marker position={position} onLoad={() => {}} animation="bounce" />
          </GoogleMap>
        ) : null}
      </div>
      <Footer />
    </Fragment>
  );
}

export default Index;
