import { useState } from "react";
import $ from "jquery";

function Sidebar({ children, collapsible, id = "page-wrap", className }) {
  const [sidenavVisible, setSidenavVisible] = useState(true);

  function toggleSidenav() {
    // check if sidenav is open or close
    if (sidenavVisible) {
      $(`#${id}`).css("transform", "translateX(-90%)");
      $("#slider-arrow").removeClass("rotate-180");
      $(".sideBarContent").hide();
      setSidenavVisible(false);
    } else {
      $(`#${id}`).css("transform", "translateX(0%)");
      $("#slider-arrow").addClass("rotate-180");
      $(".sideBarContent").show();
      setSidenavVisible(true);
    }
  }

  return (
    <div
      className={`sidenav ${className}`}
      id={id}
      style={{ position: "absolute" }}
    >
      {collapsible ? (
        <a
          href="#"
          onClick={() => {
            toggleSidenav();
          }}
        >
          <i
            className="small white-text arrow_back rotate-180 material-icons z-999"
            id="slider-arrow"
          >
            trending_flat
          </i>
        </a>
      ) : null}

      <div id="sideBarContent">{children}</div>
    </div>
  );
}

export default Sidebar;
