import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CoreUtils from "groupcore-utils";
import __ from "../../utilities/functions";
import TextInput from "../../ui/TextInput";
import SwitchButton from "../../ui/SwitchButton";
import Submit from "../../ui/Submit";
import Recaptcha from "../../ui/Recaptcha";
import Checkbox from "../../ui/Checkbox";
import config from "../../utilities/config";

function Registration() {
  const [form, setForm] = useState({});
  const dispatch = useDispatch();

  // code to handle form submisssion
  async function sendForm(e) {
    e.preventDefault();
    __.showLoadingBar(dispatch);

    if (!form.recaptcha) {
      __.hideLoadingBar(dispatch);
      __.displayErrorMessage("Please confirm you are not a robot, to proceed!");
      return;
    }


    const coreUtil = new CoreUtils({projectId: config.projectId, apiToken: config.apiToken, apiUrl: config.apiUrl})
    try{
      await coreUtil.submitForm({formData: form, formId: 23, email: form.email});
      await coreUtil.sendMail({recipientEmail: 'groupcollab2020@gmail.com', subject: 'Sample Subject', htmlBody: '<p>Sample Body</p>', hasAttachment: true, attachmentUrl: 'https://groupcore.ca/cmscdn/sample.pdf', attachmentFilename:'attachment.pdf'})
      __.hideLoadingBar(dispatch);
      __.displaySuccessMessage(
        "Your form has been sent successfully! We will get back to you shortly. Thank you!!"
      );
      __.redirect(dispatch, "/home");

    }catch(err){
      console.log('caught error', err.response.data.message)
    }
  }

  return (
    <form
      onSubmit={(e) => {
        sendForm(e);
      }}
    >
      <TextInput
        label="First Name*"
        className="col s12 l3 pa2"
        state={form}
        isRequired={true}
        id="firstname"
        setState={setForm}
        type="text"
        value={form?.firstname}
      />
      <TextInput
        label="Last Name*"
        className="col s12 l3 pa2"
        state={form}
        isRequired={true}
        id="lastname"
        setState={setForm}
        type="text"
        value={form?.lastname}
      />
      <TextInput
        label="Email*"
        className="col s12 l3 pa2"
        state={form}
        id="email"
        setState={setForm}
        type="text"
        isRequired={true}
        value={form?.email}
      />
      <TextInput
        label="Phone*"
        className="col s12 l3 pa2"
        state={form}
        id="phone"
        setState={setForm}
        type="text"
        isRequired={true}
        value={form?.phone}
      />
      <TextInput
        label="Address"
        className="col s12 l7 pa2"
        state={form}
        id="address"
        setState={setForm}
        type="text"
        isRequired={false}
        value={form?.address}
      />
      <TextInput
        label="City"
        className="col s12 l2 pa2"
        state={form}
        id="city"
        setState={setForm}
        type="text"
        isRequired={false}
        value={form?.city}
      />

      <TextInput
        label="Province"
        className="col s12 l2 pa2"
        state={form}
        id="province"
        setState={setForm}
        type="text"
        isRequired={false}
        value={form?.province}
      />

      <TextInput
        label="Postal Code"
        className="col s12 l1 pa2"
        state={form}
        id="postalCode"
        setState={setForm}
        type="text"
        isRequired={false}
        value={form?.postalCode}
      />

      <TextInput
        label="Message"
        className="col s12 pa2"
        state={form}
        id="message"
        setState={setForm}
        type="text"
        isRequired={false}
        value={form?.message}
      />

      <Recaptcha setState={setForm} className="col s12" state={form} />

      <Checkbox
        label="yes, I would like to receive email updates"
        on={true}
        setState={setForm}
        state={form}
        id="newsletter"
        className="right"
      />

      <Submit className="col s12" position="right">
        Register
      </Submit>
    </form>
  );
}

export default Registration;
