/**
 * desktop version of the building SVG with colored floor overlays
 */

import { NavLink } from "react-router-dom";

function Desktop() {
  return (
    <div
      className="dark-blue-bg hide-on-small-and-down tablet-height"
      id="backgroundSVG"
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 1130"
        className="tablet-responsive"
      >
        <image
          className="absolute overflow-auto"
          x="0"
          y="0"
          href="https://groupcore.ca/cmscdn/8ecxIdesktop.png"
        />
        <NavLink to="/floor/8">
          <path
            className="floor7 nofill"
            id="floor7"
            d="M 306.466 397.831 L 305.743 310.719 L 425.805 155.674 L 781.96 263.516 L 782.283 274.501 L 946.734 327.398 L 1010.401 304.155 L 1009.895 250.98 L 998.612 243.536 L 1186.283 46.838 C 1186.283 46.838 1471.054 114.097 1471.054 114.01 C 1471.054 113.923 1511.764 86.572 1511.764 86.572 C 1572.079 97.771 1639.81 125.326 1655.252 168.714 L 1654.848 310.276 C 1655.54 310.259 1649.932 290.281 1647.552 290.34 L 1607.481 295.897 L 1607.218 257.993 C 1584.268 242.16 1543.088 221.146 1519.217 221.229 L 1420.887 261.27 L 1166.464 214.678 L 1166.464 278.426 L 1010.091 335.991 L 1009.8 396.626 L 945.292 411.53 L 775.934 374.438 L 774.024 363.661 L 426.804 279.195 L 306.466 397.831 Z"
          />
          <circle
            className="floor_circle"
            cx="1558.244"
            cy="175.102"
            r="28.38"
          />
          <text
            className="floor_number"
            x="1545.06"
            y="175.292"
            dx="5.354"
            dy="8.923"
          >
            8
          </text>
        </NavLink>
        <NavLink to="/floor/7">
          <path
            className="floor6 nofill"
            id="floor6"
            d="M 1655.213 391.971 L 1655.061 310.149 C 1655.489 310.066 1649.536 290.119 1647.627 290.489 L 1607.554 295.866 C 1607.554 295.866 1607.294 258.063 1607.266 258.042 C 1590.001 245.264 1537.969 218.821 1519.428 221.093 C 1519.428 221.093 1421.307 261.186 1421.307 261.153 C 1421.307 261.12 1166.417 214.762 1166.417 214.762 L 1166.566 278.306 L 1010.197 335.921 L 1009.856 396.407 L 945.597 411.779 L 775.977 374.568 C 775.977 374.568 774.056 363.665 774.07 363.665 C 774.084 363.665 426.963 279.31 426.963 279.31 L 306.531 397.832 L 305.387 458.849 L 424.752 368.41 L 775.603 435.447 L 775.845 441.5 L 947.924 473.955 C 947.924 473.955 1009.828 462.062 1009.865 462.099 C 1009.902 462.136 1011.216 420.712 1011.216 420.712 L 1165.846 371.253 L 1164.86 326.813 L 1429.659 366.101 L 1520.331 338.965 C 1557.074 334.96 1579.751 344.19 1607.538 363.337 L 1607.659 380.579 L 1643.623 375.907 C 1646.07 375.091 1655.775 388.604 1655.213 391.971 Z"
          />
          <circle
            className="floor_circle"
            cx="1558.244"
            cy="290.529"
            r="28.38"
          />
          <text
            className="floor_number"
            x="1545.06"
            y="290.942"
            dx="5.354"
            dy="10.708"
          >
            7
          </text>
        </NavLink>
        <NavLink to="/floor/6">
          <path
            className="floor5 nofill"
            id="floor5"
            d="M 303.953 527.59 L 304.934 459.837 L 424.648 368.459 L 775.744 435.469 L 775.934 441.743 L 947.624 473.932 L 1009.83 462.009 L 1011.25 420.735 L 1165.634 371.216 L 1164.876 326.941 L 1429.541 366.137 C 1429.541 366.137 1499.838 344.242 1520.294 339.059 C 1540.75 333.876 1578.69 340.871 1607.555 363.501 L 1607.692 380.532 L 1643.551 375.871 C 1646.738 375.225 1656.14 390.018 1655.118 391.857 L 1656.832 479.401 C 1656.439 477.774 1650.97 470.249 1647.898 469.3 C 1647.898 469.3 1607.25 473.633 1607.25 473.718 C 1607.25 473.803 1606.625 452.072 1606.625 452.072 C 1575.232 439.711 1552.989 434.933 1520.603 437.631 L 1428.405 457.161 L 1168.439 434.235 L 1167.645 464.884 L 1010.523 500.168 L 1009.258 527.418 L 947.015 535.324 L 775.412 511.961 C 775.412 511.961 774.606 506.316 774.721 506.2 C 774.836 506.084 424.584 460.37 424.584 460.37 L 303.953 527.59 Z"
          />

          <circle
            className="floor_circle"
            cx="1558.244"
            cy="390.718"
            r="28.38"
          />
          <text
            className="floor_number"
            x="1547.06"
            y="390.454"
            dx="1.785"
            dy="13.385"
          >
            6
          </text>
        </NavLink>
        <NavLink to="/floor/5">
          <path
            className="floor4 nofill"
            id="floor4"
            d="M 303.961 587.99 L 304.01 527.537 L 424.486 460.371 L 774.614 506.172 L 775.503 512.162 C 775.503 512.162 946.932 535.395 946.965 535.395 C 946.998 535.395 1009.127 527.388 1009.127 527.388 L 1010.595 500.199 L 1167.562 464.926 C 1167.562 464.926 1168.45 434.118 1168.45 434.146 C 1168.45 434.174 1428.418 457.135 1428.418 457.135 L 1520.611 437.584 C 1564.236 432.762 1596.336 447.466 1606.686 452.215 L 1607.213 473.543 L 1647.702 469.262 L 1656.718 478.811 L 1657.037 564.749 L 1648.038 558.536 L 1608.809 561.952 L 1608.438 547.875 C 1597.442 544.42 1532.435 538.313 1522.793 541.08 L 1435.585 555.04 L 1167.09 542.584 L 1167.09 561.034 L 1009.655 577.61 L 1009.947 591.768 L 949.042 596.574 L 776.357 580.708 L 775.215 575.898 L 422.66 548.448 L 303.961 587.99 Z"
          />

          <circle
            className="floor_circle"
            cx="1558.244"
            cy="490.766"
            r="28.38"
          />
          <text
            className="floor_number"
            x="1545.06"
            y="485.257"
            dx="6.247"
            dy="17.846"
          >
            5
          </text>
        </NavLink>
        <NavLink to="/floor/4">
          <path
            className="floor3 nofill"
            id="floor3"
            d="M 302.414 652.603 L 303.906 587.873 L 422.646 548.511 L 775.137 575.863 L 776.386 580.857 L 949.066 596.595 C 949.066 596.595 1010.012 591.566 1009.969 591.652 C 1009.926 591.738 1009.611 577.499 1009.611 577.499 L 1166.969 561.081 L 1167.08 542.52 L 1435.539 555.018 L 1522.921 541.054 C 1531.062 538.217 1606.526 544.103 1608.384 547.901 L 1608.842 562.017 C 1608.842 562.017 1648.102 558.395 1648.124 558.439 C 1648.146 558.483 1657.744 565.635 1657.744 565.635 L 1658.549 650.419 L 1609.822 652.809 L 1168.289 652.111 L 1168.324 657.008 L 1009.064 657.162 L 949.022 656.439 L 422.619 638.339 L 302.414 652.603 Z"
          />
          <circle className="floor_circle" cx="1558.244" cy="599" r="28.38" />
          <text
            className="floor_number"
            x="1540.06"
            y="594.276"
            dx="10.708"
            dy="17.848"
          >
            4
          </text>
        </NavLink>
        <NavLink to="/floor/3">
          <path
            className="floor2 nofill"
            id="floor2"
            d="M 301.9 719.323 L 302.36 652.825 L 422.632 638.394 L 948.371 656.297 L 1007.646 657.194 L 1168.213 657.032 L 1168.294 652.241 L 1609.453 652.867 L 1658.578 650.435 L 1659.409 726.076 L 1653.934 726.737 L 1639.668 737.856 L 1523.412 741.991 L 1438.54 738.486 L 1277.397 742.318 L 1170.316 745.277 L 1169.729 733.344 L 1009.874 729.958 C 1009.874 729.958 1009.166 721.081 1009.102 721.016 C 1009.038 720.951 420.079 725.264 420.079 725.264 C 420.079 725.264 301.855 719.468 301.9 719.323 Z"
          />

          <circle
            className="floor_circle"
            cx="1558.244"
            cy="699.391"
            r="28.38"
          />
          <text
            className="floor_number"
            x="1545.06"
            y="680.694"
            dx="7.139"
            dy="31.232"
          >
            3
          </text>
        </NavLink>
        <NavLink to="/floor/2">
          <path
            className="floor1 nofill"
            id="floor1"
            d="M 263.495 756.792 L 302.874 755.787 L 302.003 719.364 L 420.037 725.222 L 1009.058 720.982 L 1009.98 730.155 L 1169.773 733.352 L 1170.377 745.298 C 1170.377 745.298 1438.309 738.481 1438.368 738.481 C 1438.427 738.481 1523.607 741.946 1523.607 741.946 L 1639.591 737.947 L 1653.938 726.836 L 1659.526 726.082 L 1659.272 782.913 L 1522.74 798.458 L 1003.209 808.745 L 956.153 799.289 C 956.153 799.289 526.876 815.732 527.084 815.524 C 527.292 815.316 513.637 821.836 513.637 821.836 L 263.495 756.792 Z"
          />

          <circle
            className="floor_circle"
            cx="1560.244"
            cy="775.841"
            r="28.38"
          />
          <text
            className="floor_number"
            x="1540.06"
            y="768.744"
            dx="13.385"
            dy="19.631"
          >
            2
          </text>
        </NavLink>
      </svg>
    </div>
  );
}

export default Desktop;
