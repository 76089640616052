import { Fragment, useEffect } from "react";
import HomeHeader from "../../sections/HomeHeader";
import Spacer from "../../components/Spacer";
import Footer from "../../sections/Footer";
import InnerHeader from "../../sections/InnerHeader";
import StickyHeader from "../../sections/StickyHeader";
import ConnectForm from "../../forms/Connect";
import Analytics from "../../../Analytics/Analytics";

function ConnectPage() {
  useEffect(() => {
    new Analytics().trackPageview("Connect Page");
  });
  return (
    <Fragment>
      <InnerHeader />
      <StickyHeader />
      <Spacer className="sidepadding" />

      <article className="vh-75 dt w-100 white-bg">
        <div className="dtc v-mid tc ph3 ph4-l">
          <div className="container tl">
            <div className="row">
              <div className="col s12 m12 l6 xl6">
                <h3 className="mb5">Contact Us Today</h3>
                <h6 className="mb5">
                  Have a question. We'd love to answer it. Please send us a
                  message via our contact form or give us a call.
                </h6>
                <h6 className="mb5">Phone: 780-306-4416</h6>
                <h6>
                  Address: 414, 10171 Saskatchewan Drive NW, Alberta, Canada{" "}
                </h6>
              </div>
              <div className="col s12 m12 l6 xl6" id="form-container">
                <ConnectForm />
              </div>

              <div className="col s12 l6 d-none" id="confirmation-container">
                <h3 className="light-brown-text font-garamond">
                  <i className="material-icons">done</i> Sent!
                </h3>
                <p className="brown-text">
                  Your request has been sent successfully! You will get a
                  response shortly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>

      <Footer />
    </Fragment>
  );
}

export default ConnectPage;
