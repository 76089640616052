import { useEffect } from "react";
import $ from "jquery";

const Checkbox = ({
  label,
  onClick,
  on = null,
  id,
  className,
  setState,
  state,
}) => {
  useEffect(() => {
    if (on) {
      $(`#${id}`).attr("checked", "true");
    } else {
      $("input[type=checkbox]").removeAttr("checked", "true");
    }
  }, []);

  return (
    <div className={`${className}`}>
      <label>
        <input
          id={id}
          type="checkbox"
          onClick={(e) => {
            setState({ ...state, [id]: e.target.value });
          }}
        />
        <span>{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
