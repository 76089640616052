import { Fragment, useEffect } from "react";
import Footer from "../../sections/Footer";
import InnerHeader from "../../sections/InnerHeader";
import Registration from "../../forms/Registration";
import StickyHeader from "../../sections/StickyHeader";
import Spacer from "../../components/Spacer";
import Analytics from "../../../Analytics/Analytics";

// Import Swiper styles

function Why() {
  useEffect(() => {
    new Analytics().trackPageview("Why Page");
  });

  return (
    <Fragment>
      <InnerHeader />

      <div className="fullScreenDiv">
        <div className="container headline headline-sm">
          <div className="row">
            <div className="col animate__animated animate__fadeInUp animate__delay-4s animate__slower">
              <h1 className="white-text headline_text headline_text-sm">
                Remote Tours
              </h1>
              <h2 className="white-text f3-l f4-m mb3 f5">
                Find, tour and reserve your new apartment from anywhere!
              </h2>
              <h2 className="white-text f4-l mt0 f5 right mb4">
                *posession may 1st, 2022
              </h2>
            </div>
          </div>
        </div>
        <video muted autoPlay playsInline className="video_banner">
          <source
            src="https://groupcore.ca/cmscdn/PG1ZBWhy_StA_Banners_V1.mp4"
            type="video/mp4"
          />
        </video>
        <StickyHeader />
      </div>

      <Spacer className="sidepadding" />

      <div className="container-fluid white-bg sidepadding pv4">
        <div className="row">
          <div className="col l4 xl4 hide-on-med-and-down">
            <div className="ml5 mt5">
              <span className="f4 section_num">01</span>
              <p className="f4 section_title">
                Zero Emission
                <br />
                Innovation
              </p>
            </div>
          </div>
          <div className="col s12 m12 l8 xl8 pl0">
            <div className="row">
              <div className="col s12 m12 l12 xl12 pl1">
                <h1 className="dark-blue-text ma0 f1 lh-title">
                  Save Money with
                </h1>
                <h1 className="dark-blue-text ma0 f1 lh-title">
                  Geothermal Heading & Cooling
                </h1>
              </div>
              <div className="col s12 m12 l6 xl6 f4 pl1">
                <p className="didot-medium dark-blue-text lh-copy tj">
                  Our purpose is to develop sustainable zero emission homes and
                  buildings to help create a long term infrastructure for zero
                  carbon communities in order to meet global and local climate
                  ordinances. To meet the global carbon emission goals set by
                  world leaders at the 2015 Paris Climate Change Conference, we
                  will all need to be living, working, and commuting with net
                  zero energy use by 2050.
                </p>
                <p className="didot-medium dark-blue-text tj lh-copy">
                  As Western Canada’s premier zero emission developer, we
                  understand the need to change how we live, travel and consume.
                </p>
              </div>
              <div className="col s12 m12 l6 xl6 f4 pl1">
                <p className="didot-medium dark-blue-text lh-copy tj">
                  {" "}
                  We recognize that NOW is the time to start on the path towards
                  zero emission living in our personal lives, in our businesses
                  and community developments. We believe everyone has a role in
                  environmental stewardship, and zero emission living is the
                  best way to advocate for a social and economical shift towards
                  real sustainability.
                </p>
                <p className="didot-medium dark-blue-text lh-copy tj">
                  We are an ethical development company, inspired by
                  sustainability, durability and zero energy innovation.
                </p>
                <p className="dark-blue-text">
                  This is our <em className="futura-medium b">niche.</em>
                </p>
                <p>
                  <a
                    className="underline-hover dark-blue-text fw6 db link dim"
                    href="https://nichedevelopment.ca/zero_emission"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Benefits of living in a zero emission home.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid sidepadding white-bg pv3">
        <div className="row">
          <div className="col s12 m12 l8 xl8 pl0 right no-padding-r-sm relative">
            <video className="responsive-video" controls>
              <source
                src="https://groupcore.ca/cmscdn/8tP37STA_WhyCAre_V2.mp4#t=1"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>

      <div className="container-fluid white-bg sidepadding pv3">
        <div className="row">
          <div className="col l4 xl4 hide-on-med-and-down">
            <div className="ml5">
              <span className="f4 section_num">02</span>
              <p className="f4 section_title">
                Concrete &
                <br />
                Steel Durability
              </p>
            </div>
          </div>
          <div className="col s12 m12 l8 xl8 pl0">
            <div className="row">
              <div className="col s12 m12 l12 xl12 pl1">
                <h1 className="dark-blue-text ma0 f1 lh-title">
                  Enjoy Peace of Mind with
                </h1>
                <h1 className="dark-blue-text ma0 f1 lh-title">
                  Concrete & Steel Construction
                </h1>
              </div>
              <div className="col s12 m12 l6 xl6 f4 pl1">
                <p className="didot-medium dark-blue-text lh-copy tj">
                  A zero-emission building combines advanced concrete and steel
                  engineering and superior building systems with energy
                  efficiency, on-site solar panels and geothermal technology to
                  produce a better home. It is also these innovations that make
                  zero-energy and zero-emission buildings inherently more
                  durable, comfortable, quiet, sustainable and most importantly,
                  affordable.
                </p>
                <p className="didot-medium dark-blue-text tj lh-copy">
                  Simply put zero energy developments are built to a higher
                  standard, so you’ll breathe cleaner, fresher air, enjoy the
                  quiet nature of concrete and steel construction and be able to
                  regulate your environment in a much more comfortable way, free
                  from allergens and toxins.
                </p>
              </div>
              <div className="col s12 m12 l6 xl6 f4 pl1">
                <p className="didot-medium dark-blue-text lh-copy tj">
                  From then first day you move in to your home the total cost of
                  living is lower than that of a comparable standard home.
                  Zero-emission and zero-energy innovations will protect you
                  from a rapid rise in energy prices indefinitely.
                </p>
                <p className="didot-medium dark-blue-text lh-copy tj">
                  Global climate change is today’s most pressing issue and most
                  North American want to take meaningful action. Buildings alone
                  are responsible for 40% of the total energy used in the North
                  America. Zero energy homes can potentially be one of the
                  largest contributing factors of a reduced carbon future.
                  Simply living in a zero energy home means you are taking
                  meaningful steps to ethical consumerism.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid white-bg sidepadding pt5 pb3">
        <div className="row">
          <div className="col s12 l4 xl4 hide-on-med-and-down">
            <div className="ml5" />
          </div>
          <div className="pa-0 col s12 m12 l8 xl8">
            <div className="pa-0 col s12 m4 l4 xl4">
              <div className="white-bg">
                <img
                  className="materialboxed img-height-vh z-depth-4 w-100"
                  src="https://groupcore.ca/cmscdn/nBGJFM5xewLayer 9.png"
                  alt="layer"
                />
              </div>
            </div>
            <div className="ph-3 col s12 m8 l8 xl8">
              <div className="white-bg">
                <img
                  className="materialboxed img-height-vh w-100 z-depth-4"
                  src="https://groupcore.ca/cmscdn/pqsXGRectangle 1.png"
                  alt="rectangle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid white-bg sidepadding pv3">
        <div className="row">
          <div className="col l4 xl4 hide-on-med-and-down">
            <div className="ml5">
              <span className="f4 section_num">03</span>
              <p className="f4 section_title">
                Professionals &
                <br />
                Commuters
              </p>
            </div>
          </div>
          <div className="col s12 m12 l8 xl8 pl0">
            <div className="row">
              <div className="col s12 m12 l12 xl12 pl1">
                <h1 className="dark-blue-text ma0 f1 lh-title">
                  Perfect Location for
                </h1>
                <h1 className="dark-blue-text ma0 f1 lh-title">
                  Professionals & Commuters
                </h1>
              </div>
              <div className="col s12 m12 l6 xl6 f4 pl1">
                <p className="didot-medium dark-blue-text lh-copy tj">
                  Making St. Albert your home will be one of the best decisions
                  you’ll ever make. Located minutes from Edmonton, St. Alberts
                  provides a quality lifestyle, with a secure, small town feel,
                  without losing any access to everything a major metropolitan
                  has to offer.
                </p>
              </div>
              <div className="col s12 m12 l6 xl6 f4 pl1">
                <p className="didot-medium dark-blue-text lh-copy tj">
                  {" "}
                  The most obvious takeaways of St. Albert are the mature
                  tree-lined streets, a bustling downtown district, proximity
                  and access to the provinces capital and expanses of natural
                  reserves. Not so obvious is this small city boasts low
                  unemployment rates, low crime rates, good schools, quality
                  healthcare, parks and trails, recreational facilities and has
                  been recognized on a national level as one of the best places
                  to live in Canada on several occasions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid white-bg sidepadding pv3">
        <div className="row">
          <div className="col xl4"></div>

          <div className="col xl8 vh-75 pl0">
            <iframe
              src="https://stasquare.ca/iframes/whyslider.html"
              title="why saint a slider"
              style={{ height: "100%", width: "100%", border: "none" }}
            />
          </div>
        </div>
      </div>

      <div className="container-fluid white-bg sidepadding section">
        <div className="row">
          <div className="col l4 xl4 hide-on-med-and-down">
            <div className="ml5">
              <span className="f4 section_num">04</span>
              <p className="f4 section_title">
                Pre-Register <br />
                Today
              </p>
            </div>
          </div>

          <div className="col s12 l8 xl8 pl0 no-margin-top">
            <div className="row">
              <div
                className="col s12 m12 l12 xl12 padding-l2-m no-margin-top"
                id="form-container"
              >
                <Registration />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Why;
