function LocationNav({ className }) {
  return (
    <div className="white-text vh-100 overflow-scroll">
      <div className="pa4 tj">
        <h1 className="f2 tc pt5 pb3">St. [A] Locations</h1>
        <p>
          Braeside, one the first neighbourhoods established in St. Albert, has
          always taken advantage of its location along the Sturgeon River and
          it’s lush and plentiful green spaces.
        </p>

        <p>
          Characteristic of this neighbourhood includes a picturesque, mature,
          walkable community on the banks of the Sturgeon River, a footbridge to
          St. Albert Centre, easy access to major commuter routes, home to the
          St. Albert Track & Field Club and ample green space
        </p>

        <p>
          To live in or visit Braeside is to experience some of the earliest
          history of St. Albert. It is a beautiful location that perfectly
          integrates nature into the community.
        </p>
      </div>

      <div className="pa4 tj">
        <img
          src="https://groupcore.ca/cmscdn/Orailarden.jpg"
          alt="arden"
        />
        <p className="pt2">
          Arden
          <br />
          Escape the Ordinary entertainment and see for yourself why this
          intimate theatre, nestled in the heart of downtown St. Albert, has
          earned a reputation as one of the best venues in Canada for over 35
          years.
        </p>
      </div>

      <div className="pa4 tj">
        <img
          src="https://groupcore.ca/cmscdn/MGJWGst_albert_place.jpg"
          alt="st albert place"
        />
        <p className="pt2">
          St. Albert Place
          <br />
          As stages go, St. Albert Place is the largest this city has ever
          known. Home to government, history, the arts, culture and learning,
          the building is a monument to what's possible when a community comes
          together
        </p>
      </div>

      <div className="pa4 tj">
        <img
          src="https://groupcore.ca/cmscdn/lPnr9art-gallery.jpg"
          alt="art gallery of st albert"
        />
        <p className="pt2">
          Art Gallery of St. Albert
          <br />
          This features contemporary and community exhibitions, brought to life
          with public programs and events. Education programs offer visual arts
          opportunities for thousands of children, youth and adults throughout
          the year.
        </p>
      </div>

      <div className="pa4 tj">
        <img
          src="https://groupcore.ca/cmscdn/K12WHred_willow.jpg"
          alt="red willow trail system"
        />
        <p className="pt2">
          Red Willow Trail System
          <br />
          The City of St. Albert has a beautiful trail system that winds
          throughout its lush landscape. Covering 99.5 kilometres, the system
          stretches down the river valley connecting to the city's major parks,
          neighbourhoods, and urban areas. Everyone can enjoy the trails by
          walking, cycling, in-line skating, or running.
        </p>
      </div>
    </div>
  );
}

export default LocationNav;
