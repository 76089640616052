/**
 * file contains the content of the sidebar for the building profile page
 * also contains the logic for the availability slider
 */

import { useEffect } from "react";
import M from "materialize-css";
import $ from "jquery";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import Sidebar from "../../components/Sidebar";

function Nav() {
  useEffect(() => {
    M.Collapsible.init($(".collapsible"));
  }, []);

  // this function is called whenever the slider moves
  function showFloorOverlay(floor) {
    // loop through floors to know whether to show them or not
    for (let i = 1; i <= 7; i += 1) {
      if (i >= floor) {
        $(`#floor${i}`).removeClass("nofill");
        $(`#floor${i}_mobile`).removeClass("nofill");
      } else {
        $(`#floor${i}`).addClass("nofill");
        $(`#floor${i}_mobile`).addClass("nofill");
      }
    }
  }

  return (
    <div>
      <div className="row mt5-ns mt4">
        <div className="col">
          <h1 className="w-60 white-text f2 lh-title mb4">
            Find your new home!
          </h1>
          <p className="white-text f4 measure ">
            Select from the criteria below to find the perfect home for you at
            Saint [A]. After selecting your variables, simply “click” on the
            floor you would like to view the suites on!
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m12 l12 xl12 no-pd-hz f4 tj">
          <ul className="collapsible expandable">
            {/* <li className="coll active"> */}
            {/*  <div className="collapsible-header white-text border"> */}
            {/*    <div className="flex"> */}
            {/*      <p className="pl3 ma2">apartment pricing</p> */}
            {/*    </div> */}
            {/*  </div> */}
            {/*  <div className="collapsible-body collapsible-box"> */}
            {/*    <div className="row mv3 ph4"> */}
            {/*      <div className="col s12 l12 xl12 m12 pl1"> */}
            {/*        <div className="mt5 mb6 mh3"> */}
            {/*          <Nouislider */}
            {/*            start={500} */}
            {/*            connect={[true, false]} */}
            {/*            step={250} */}
            {/*            orientation="horizontal" */}
            {/*            range={{ */}
            {/*              min: 500, */}
            {/*              max: 2000, */}
            {/*            }} */}
            {/*            tooltips={true} */}
            {/*            pips={{ mode: "steps", stepped: true, density: 4 }} */}
            {/*            format={{ */}
            {/*              to: (value) => `$${Math.round(value)}`, */}
            {/*              from: (value) => value.slice(1), */}
            {/*            }} */}
            {/*            onUpdate={(...args) => { */}
            {/*              const num = Math.round(Number(args[2])); */}
            {/*              switch (num) { */}
            {/*                case 750: */}
            {/*                  showFloorOverlay(7); */}
            {/*                  break; */}

            {/*                case 1000: */}
            {/*                  showFloorOverlay(6); */}
            {/*                  break; */}

            {/*                case 1250: */}
            {/*                  showFloorOverlay(5); */}
            {/*                  break; */}

            {/*                case 1500: */}
            {/*                  showFloorOverlay(4); */}
            {/*                  break; */}

            {/*                case 1750: */}
            {/*                  showFloorOverlay(3); */}
            {/*                  break; */}

            {/*                case 2000: */}
            {/*                  showFloorOverlay(2); */}
            {/*                  showFloorOverlay(1); */}
            {/*                  break; */}

            {/*                default: */}
            {/*                  showFloorOverlay(10); // im specifying a floor that is not available so that no floor is shown when on 500 */}
            {/*                  break; */}
            {/*              } */}
            {/*            }} */}
            {/*          /> */}
            {/*        </div> */}
            {/*      </div> */}
            {/*    </div> */}
            {/*  </div> */}
            {/* </li> */}

            <li className="coll active">
              <div className="collapsible-header white-text border">
                <div className="flex">
                  <p className="pl3 ma2">apartment availability</p>
                </div>
              </div>
              <div className="collapsible-body collapsible-box">
                <div className="row mv3 ph4">
                  <div className="col s12 l12 xl12 m12 pl1">
                    <div className="mt5 mb6 mh3">
                      <Nouislider
                        start={500}
                        connect={[true, false]}
                        step={250}
                        orientation="horizontal"
                        range={{
                          min: 500,
                          max: 1750,
                        }}
                        tooltips={true}
                        format={{
                          to: (value) => {
                            switch (value) {
                              case 750:
                                return "Studio";

                              case 1000:
                                return "1 Bed";

                              case 1250:
                                return "1 Bed + Den";

                              case 1500:
                                return "2 Beds";

                              case 1750:
                                return "2 Beds + Den";

                              default:
                                return "Slide me!";
                            }
                          },
                          from: (value) => value.slice(1),
                        }}
                        onUpdate={(...args) => {
                          const num = Math.round(Number(args[2]));
                          switch (num) {
                            case 750:
                            case 1000:
                            case 1250:
                            case 1500:
                            case 1750:
                              showFloorOverlay(1);
                              break;

                            default:
                              showFloorOverlay(10); // im specifying a floor that is not available so that no floor is shown when on 500
                              break;
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Nav;
