import { Fragment, useEffect } from "react";
import HomeHeader from "../../sections/HomeHeader";
import Spacer from "../../components/Spacer";
import Reservation from "../../forms/Reservation";
import Footer from "../../sections/Footer";
import InnerHeader from "../../sections/InnerHeader";
import StickyHeader from "../../sections/StickyHeader";
import Analytics from "../../../Analytics/Analytics";

function ApplicationPage() {
  useEffect(() => {
    new Analytics().trackPageview("Application Page");
  });

  return (
    <Fragment>
      <InnerHeader />
      <StickyHeader />
      <Spacer className="sidepadding" />

      <div className="container-fluid white-bg sidepadding section">
        <div className="row">
          <div className="col l4 xl4 hide-on-med-and-down">
            <div className="ml5">
              <span className="f4 section_num">01</span>
              <p className="f4 section_title">Application</p>
            </div>
          </div>
          <div className="col hide-on-large-and-up">
            <h1 className="dark-blue-text">Application</h1>
          </div>
          <div className="col s12 l8 xl8 pl0 no-margin-top">
            <div className="row">
              <div className="col s12 m12 l12 xl12 pl1">
                <h1 className="dark-blue-text ma0 f1 lh-title">Application</h1>
              </div>
              <div className="col s12 m12 l12 xl12 padding-l2-m no-margin-top">
                <p className="dark-blue-text f4 lh-copy futura-light no-margin pl0 pt5">
                  Welcome to St. [A] Square, and thank you for your reservation
                  request. Possession of suites will commence May 1, 2022. Your
                  reservation request is a 3 part process
                </p>

                <p className="dark-blue-text f4 lh-copy futura-light no-margin pl0 pt3">
                  1. Fill out the SECURE document for the St [A] Square rental
                  management division. The PDF below is a fillable PDF, once you
                  fill it out, please send to secure@stasquare.ca
                </p>

                <p className="dark-blue-text f4 lh-copy futura-light no-margin pl0 pt3">
                  2. Once we receive your application, you will receive
                  instruction through e-mail on submitting your $150.00* deposit
                  to hold your desired suite. — *deposits are refundable if your
                  application is NOT approved, less administration processing
                  fees. Accepted applicants will have the deposit applied to
                  their damage deposit.
                </p>

                <p className="dark-blue-text f4 lh-copy futura-light no-margin pl0 pt3">
                  3. The final step is completing your Lease Forms, which will
                  be sent to you with your approval notice.
                </p>
                <p className="dark-blue-text f4 lh-copy futura-light  no-margin pl0 pt3">
                  <a href="https://groupcore.ca/cmscdn/StA_Square_Rental-Application.pdf">
                    Download PDF form
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
}

export default ApplicationPage;
