function Spacer({ className }) {
  return (
    <div className={`container-fluid hide-on-med-and-down ${className}`}>
      <div className="row no-margin-bottom">
        <div className="col l4 xl4 dark-blue-bg h3" />
        <div className="col l8 xl8 grey-bg h3" />
      </div>
    </div>
  );
}

export default Spacer;
