import ReCAPTCHA from "react-google-recaptcha";

function Recaptcha({ state, setState, className }) {
  return (
    <div className={className}>
      <ReCAPTCHA
        sitekey="6LdjEfsZAAAAAOq0MUFIALwlVRTDN6owISFCPSue"
        onChange={() => {
          setState({ ...state, recaptcha: true });
        }}
      />
    </div>
  );
}

export default Recaptcha;
