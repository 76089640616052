/**
 * landing file for the floor page, displays either desktop or mobile version depending on the size of the screen.
 */

import { Fragment, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Sidebar from "../../components/Sidebar";
import FloorNav from "./Nav";
import Floor from "./svg/Floor";
import InnerHeader from "../../sections/InnerHeader";
import StickyHeader from "../../sections/StickyHeader";
import Footer from "../../sections/Footer";
import MobileFloor from "./svg/MobileFloor";
import Buttons from "./Buttons";
import Spacer from "../../components/Spacer";
import Analytics from "../../../Analytics/Analytics";

function Index() {
  useEffect(() => {
    new Analytics().trackPageview("Floor Page");
  });
  const { floor } = useParams();
  const dispatch = useDispatch();

  return (
    <Fragment>
      <InnerHeader />
      <StickyHeader />
      <div id="floorSVG">
        <Floor number={floor} />
        <MobileFloor number={floor} />

        <Sidebar collapsible={true}>
          <FloorNav floor={floor} />
        </Sidebar>

        <Buttons floor={floor} />
      </div>
      <Spacer />
      <Footer />
    </Fragment>
  );
}

export default Index;
