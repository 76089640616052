import ReactGA from "react-ga";

ReactGA.initialize("UA-184621666-3");

class Analytics {
  trackPageview(page) {
    return ReactGA.pageview(page);
  }
}

export default Analytics;
