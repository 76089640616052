import { NavLink } from "react-router-dom";
import Menu from "../components/Menu";
import StickyHeader from "./StickyHeader";
import MobileMenu from "../components/MobileMenu";

function InnerHeader() {
  return (
    <nav>
      <div className="nav-wrapper z-depth-4 z-max">
        <div id="navbar">
          <div className="wrapper dark-blue-bg">
            <div className="nav-wrapper">
              <div className="row mb0">
                <div className="col s6 m4 l3 xl3">
                  <NavLink to="/">
                    <img
                      className="pa3 ma2"
                      src="https://groupcore.ca/cmscdn/ZusXs8GIC8St.[A].png"
                      alt="logo"
                    />
                  </NavLink>
                </div>
                <div className="col s6 m8 l9 xl9 ">
                  <a
                    href="#"
                    data-target="mobile-demo"
                    className="sidenav-trigger ma3 white-text hide-on-large-and-up right"
                  >
                    <i className="material-icons mt3">menu</i>
                  </a>
                  <ul className="right mt4 nav hide-on-med-and-down">
                    <Menu />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileMenu />
    </nav>
  );
}
export default InnerHeader;
