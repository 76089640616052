import { useEffect } from "react";
import M from "materialize-css";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import Menu from "./Menu";

function MobileMenu({ id }) {
  useEffect(() => {
    M.Sidenav.init(document.querySelector("#mobile-demo"));
  }, []);
  return (
    <ul className="sidenav" id="mobile-demo">
      <Menu />
    </ul>
  );
}

export default withRouter(MobileMenu);
